import { api } from '../../../api';
import { ApiResponse } from '../../Utilities';
import { CreateUpdateDeleteViewModel, DashboardReportMetadataViewModel } from '../../ViewModels';
import { GetDashboardReportMetadataParams } from './Params/GetDashboardReportMetadataParams';

/**
 * DashboardReportMetadataApi
 *
 * This class provides methods to interact with the Dashboard Report Metadata API.
 * It includes functionalities to fetch, create, update, and delete dashboard report metadata.
 */
export class DashboardReportMetadataApi {
    private route = 'Report/DashboardReportMetadata';

    public getDashboardReportMetadata = (
        params: GetDashboardReportMetadataParams
    ): ApiResponse<DashboardReportMetadataViewModel[]> => {
        return api().get(`${this.route}`, {
            params: params,
        });
    };

    public getDashboardReportMetadataById = (id: number): ApiResponse<DashboardReportMetadataViewModel> => {
        return api().get(`${this.route}/${id}`);
    };

    public postDashboardReportMetadata = (data: DashboardReportMetadataViewModel): ApiResponse<number> => {
        return api().post(`${this.route}`, data);
    };

    public putDashboardReportMetadata = (id: number, data: DashboardReportMetadataViewModel): ApiResponse<void> => {
        return api().put(`${this.route}/${id}`, data);
    };

    public deleteDashboardReportMetadata = (id: number): ApiResponse<CreateUpdateDeleteViewModel> => {
        return api().delete(`${this.route}/${id}`);
    };
}
