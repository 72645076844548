export default {
    /* auth hive */
    login: '/auth/login',
    logout: '/auth/logout',
    passwordRecover: '/Auth/ResetPassword',
    /* users hive */
    getAllRoles: '/User/Roles',
    user: '/User/',
    getUserByToken: '/User/Token',
    changePassword: 'User/ChangePassword',
    /* companies hive */
    companyDepartmentList: 'Company/Department',
    tradingPartners: 'Company/TradingPartners',
    customerLocation: 'Company/TradingPartners/Customer',
    updateCustomerLocation: 'Company/TradingPartners/Customer/:ID',
    glAccountList: 'Company/GLAccount',
    listVendors: '/Company/Vendor?companyId=:id',
    readCompany: '/Company/:companyId',
    updateCompany: '/Company/:companyId',
    companyBuyerPortalSettings: '/Company/BuyerPortalSettings?companyId=:id',
    searchCompany: 'Companies/Search',
    vatArchiveService: 'Company/VatArchiveService',
    updateVatArchiveService: 'Company/VatArchiveService/:id',
    companyDynamicDiscountingDocuments: 'Company/DynamicDiscounting/Document',
    updateTradingPartners: 'Company/TradingPartners/:supplierCompanyId',
    technicalDocuments: 'Company/TechnicalDocuments',
    readTechnicalDocument: 'Company/TechnicalDocuments/:documentId',
    readSIMDocumentTypeList: 'DocumentType?companyId=:companyId',
    readVendorClassList: 'Company/VendorClass?companyId=:companyId',
    glAccountStructure: 'Company/GLAccountStructure',
    companyCustomDataSet: 'Company/CompanyCustomDataset',
    /* document type hive */
    readSupplierSIMRequestList: 'DocumentType/SupplierSIMRequest?isBuyer=:isBuyer&companyId=:companyId',
    readSIMDocumentType: 'DocumentType/:id',
    documentType: '/DocumentType/',
    updateSIMDocumentType: 'DocumentType/:id',
    readSupplierSIMRequest: '/DocumentType/SupplierSIMRequest/:id',
    /* documents hive */
    documentDelivery: 'Document/delivery',
    documentAttachments: '/Document/Attachments',
    deleteDocumentAttachment: '/Document/Attachments/:id',
    downloadDocumentAttachment: 'Document/Attachments/Download',
    downloadDeliveredDocument: '/Document/Delivery/Download/:deliveryGroupId?fileName=:fileName',
    saveDocumentAttachments: '/Document/Attachments?documentId=:documentId',
    documentDraft: 'Document/Draft',
    documentDraftSave: 'Document/Draft/:documentId',
    documentHistory: '/Document/history',
    documentLayout: '/Document/Layout',
    dynamicDiscounting: '/Document/dynamicdiscounting',
    fetchPDF: '/Document/GeneratePDF',
    generatePDF: '/Document/Draft/GeneratePDF',
    parkingLotDocumentsCount: 'Document/ParkingLotDocumentCounts',
    readDocument: '/Document/:id',
    document: '/Document',
    validateDocument: '/Document/Validations',
    validateDraftDocument: '/Document/Validate',
    documentDraftAttachments: '/Document/Draft/Attachments',
    saveDocumentDraftAttachments:
        '/Document/Draft/Attachments?&businessDocType=:businessDocType&businessDocumentDraftId=:draftId&documentId=:documentId',
    flipDocument: '/Document/Flip',
    documentSkeleton: '/Document/Skeleton',
    resendDocument: '/Document?documentIdToClone=:documentId',
    documentSearchConfiguration: '/Document/SearchConfiguration',
    documentBuyerMessage: '/Document/Messaging',
    documentActiveWorkflowDocumentCount: '/Document/ActiveWorkflowDocumentCounts',
    /* common hive */
    country: 'Common/Country',
    currencyList: '/common/currency',
    standardStyleSheet: 'Common/StandardStyleSheet',
    state: 'Common/State',
    contact: 'Contact',
    /* profiles hive */
    newProfile: '/Profile/?contact=:contact&email=:email&phone=:phone',
    profile: '/Profile/:profileId?contact=:contact&email=:email&phone=:phone',
    profiles: '/Profile',
    profileSearch: 'Profile/Search?companyid=:companyId&profiletype=:profileType',
    profileTypes: '/Profile/:profileId?profileType=:profileType',
    /* help resources hive */
    fetchHelpResources: '/helpresource',
    fetchHelpResourcesSearch: 'helpresource/Search',
    getNoticePage:
        '/helpresource?$filter=Enabled eq true and PortalResourceContents/any(c: c/Portal eq 2 and c/Page eq 17 and c/Context eq 0)',
    fetchCategories: '/helpresource/category',
    /* service agreement hive */
    companyServiceAgreement: '/ServiceAgreement/CompanyServiceAgreement?companyId=:id',
    companyServiceAgreementContent:
        '/ServiceAgreement/ServiceAgreementContent?serviceAgreementId=:id&includeAllVersions=false',
    createServiceAgreementSignature: '/ServiceAgreement/CompanyServiceAgreementSignature',
    /* workflows hive */
    documentWorkflowInstance: '/WorkflowInstance',
    getWorkflowActivity: 'Workflow/WorkflowActivity/',
    menuSettings: '/Company/BuyerPortalSettings',
    reassignWorkflowUsers: '/WorkflowTask/ReassignWorkflowUsers',
    updateWorkflowTask: '/WorkflowTask/:taskId',
    updateWorkflowInstance: '/WorkflowInstance/:instanceId',
    /* report hive */
    report: '/report',
    updateReport: '/report/:reportID',
    reportDefinitions: '/report/definition/',
    /* SSO hive */
    readSSOSettings: '/company/sso',
    /* user groups hive */
    userGroup: '/UserGroup/',
};
