import { useState } from 'react';
import { FCNC, Button } from '../../ui';
import { Box, Alert, Typography } from '@mui/material';
import { useUserNotification, IUseUserNotification } from './useUserNotification';
import { UserNotificationPortalAcknowledgement } from '../../types/Enums';
import { backendServices, useCurrentUser } from '../../services';

/* eslint-disable react/no-danger */
export const UserNotification: FCNC<IUseUserNotification> = ({ loginPage, isBuyerPortal }) => {
    const [acknowledgementId, setAcknowledgementId] = useState<number | undefined>(undefined);
    const { data: notifications } = useUserNotification({ loginPage, isBuyerPortal, acknowledgementId });

    const notificationsWithNoAck = notifications?.Items.filter(
        (n) => n.PortalAcknowledgement === UserNotificationPortalAcknowledgement.None
    );

    const notificationsWithBlockingAck = notifications?.Items.filter(
        (n) => n.PortalAcknowledgement === UserNotificationPortalAcknowledgement.YesWithBlocking
    );

    const notificationsWithNoBlockingAck = notifications?.Items.filter(
        (n) => n.PortalAcknowledgement === UserNotificationPortalAcknowledgement.YesWithNoBlocking
    );

    const userNotificationApi = new backendServices.Apis.UserNotificationApi();

    const userId = useCurrentUser()?.id;

    return (
        <>
            {notificationsWithBlockingAck && notificationsWithBlockingAck.length && !loginPage ? (
                <>
                    {notificationsWithBlockingAck.map((n) => (
                        <Alert
                            key={n.Id}
                            data-testid="alert-portal-notification-yes-blocking"
                            severity="error"
                            sx={{
                                fontSize: 'inherit',
                                color: 'inherit',
                                bgcolor: '#1a1d2c',
                                marginBottom: '0.5rem',
                                zIndex: 10,
                            }}
                            action={
                                <Button
                                    testid={'alert-portal-notification-yes-blocking-acknowledgement-button'}
                                    color="warn"
                                    onClick={async () => {
                                        await userNotificationApi.acknowledgeNotification(userId, n.Id);
                                        setAcknowledgementId(n.Id);
                                    }}
                                >
                                    Got It
                                </Button>
                            }
                        >
                            <Typography
                                sx={{
                                    color: 'white',
                                }}
                                dangerouslySetInnerHTML={{ __html: n.NotificationHTML }}
                            ></Typography>
                        </Alert>
                    ))}
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            zIndex: 5,
                        }}
                    />
                </>
            ) : null}
            {notificationsWithNoAck && notificationsWithNoAck.length
                ? notificationsWithNoAck.map((n) => (
                      <Alert
                          key={n.Id}
                          data-testid={loginPage ? 'alert-login-notification-none' : 'alert-portal-notification-none'}
                          severity="warning"
                          sx={{
                              fontSize: 'inherit',
                              color: 'inherit',
                              marginBottom: '0.5rem',
                          }}
                      >
                          <Typography dangerouslySetInnerHTML={{ __html: n.NotificationHTML }}></Typography>
                      </Alert>
                  ))
                : null}
            {notificationsWithNoBlockingAck && notificationsWithNoBlockingAck.length && !loginPage
                ? notificationsWithNoBlockingAck.map((n) => (
                      <Alert
                          key={n.Id}
                          data-testid="alert-portal-notification-yes-no-blocking"
                          severity="warning"
                          sx={{
                              fontSize: 'inherit',
                              color: 'inherit',
                              marginBottom: '0.5rem',
                          }}
                          action={
                              <Button
                                  testid={'alert-portal-notification-yes-no-blocking-acknowledgement-button'}
                                  color="warn"
                                  onClick={async () => {
                                      await userNotificationApi.acknowledgeNotification(userId, n.Id);
                                      setAcknowledgementId(n.Id);
                                  }}
                              >
                                  Got It
                              </Button>
                          }
                      >
                          <Typography dangerouslySetInnerHTML={{ __html: n.NotificationHTML }}></Typography>
                      </Alert>
                  ))
                : null}
        </>
    );
};
