import { Box, BoxProps, InputAdornment } from '@mui/material';
import { Event as Calendar } from '@mui/icons-material';
import {
    DateRange,
    DateRangePicker as MuiDateRangePicker,
    DateRangePickerToolbarProps,
    LocalizationProvider,
    PickersShortcutsItem,
    RangePosition,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { differenceInDays, differenceInMilliseconds, endOfDay, startOfDay } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { Button } from '../Button';
import { FCNC } from '../FCNC';
import { useFieldsReadonly } from '../Form';
import { ReadOnly } from '../ReadOnly';
import { ITooltipIconProps, renderTooltipLabel } from '../Tooltip';
import { getDatesFromShortcut, DateRangePickerShortcut } from '../DateRangePicker/DateRangePicker.utils';

enum DateRangePickerShortcutStrings {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    PAST_WEEK = 'Past Week',
    PAST_MONTH = 'Past Month',
    PAST_3_MONTHS = 'Past 3 Months',
    PAST_6_MONTHS = 'Past 6 Months',
}

const DateRangePickerShortcutStringToEnumTable: { [key in DateRangePickerShortcutStrings]: DateRangePickerShortcut } = {
    [DateRangePickerShortcutStrings.TODAY]: DateRangePickerShortcut.TODAY,
    [DateRangePickerShortcutStrings.YESTERDAY]: DateRangePickerShortcut.YESTERDAY,
    [DateRangePickerShortcutStrings.PAST_WEEK]: DateRangePickerShortcut.PAST_WEEK,
    [DateRangePickerShortcutStrings.PAST_MONTH]: DateRangePickerShortcut.PAST_MONTH,
    [DateRangePickerShortcutStrings.PAST_3_MONTHS]: DateRangePickerShortcut.PAST_3_MONTHS,
    [DateRangePickerShortcutStrings.PAST_6_MONTHS]: DateRangePickerShortcut.PAST_6_MONTHS,
};

const DATE_RANGE_PICKER_SHORTCUT: { [key in DateRangePickerShortcut]: PickersShortcutsItem<DateRange<Date>> } = {
    [DateRangePickerShortcut.TODAY]: {
        label: DateRangePickerShortcutStrings.TODAY,
        getValue: () => getDatesFromShortcut(DateRangePickerShortcut.TODAY),
    },
    [DateRangePickerShortcut.YESTERDAY]: {
        label: DateRangePickerShortcutStrings.YESTERDAY,
        getValue: () => getDatesFromShortcut(DateRangePickerShortcut.YESTERDAY),
    },
    [DateRangePickerShortcut.PAST_WEEK]: {
        label: DateRangePickerShortcutStrings.PAST_WEEK,
        getValue: () => getDatesFromShortcut(DateRangePickerShortcut.PAST_WEEK),
    },
    [DateRangePickerShortcut.PAST_MONTH]: {
        label: DateRangePickerShortcutStrings.PAST_MONTH,
        getValue: () => getDatesFromShortcut(DateRangePickerShortcut.PAST_MONTH),
    },
    [DateRangePickerShortcut.PAST_3_MONTHS]: {
        label: DateRangePickerShortcutStrings.PAST_3_MONTHS,
        getValue: () => getDatesFromShortcut(DateRangePickerShortcut.PAST_3_MONTHS),
    },
    [DateRangePickerShortcut.PAST_6_MONTHS]: {
        label: DateRangePickerShortcutStrings.PAST_6_MONTHS,
        getValue: () => getDatesFromShortcut(DateRangePickerShortcut.PAST_6_MONTHS),
    },
};

export interface IDateRangePickerFieldProps {
    value: DateRange<Date>;
    onChange: (date: DateRange<Date>, shortcut: DateRangePickerShortcut | null) => void;
    placeholders?: [string, string];
    maxDate?: Date;
    minDate?: Date;
    testId?: string;
    id?: string;
    stacked?: boolean;
    label?: string;
    labelBoxSx?: BoxProps['sx'];
    tooltip?: ITooltipIconProps;
    readonly?: boolean;
    required?: boolean;
    shortcuts?: DateRangePickerShortcut[];
}

const CustomDateRangePickerToolbar = ({ onChange, value }: DateRangePickerToolbarProps<Date> & { onChange: any }) => {
    const onClick = (start: Date, end: Date, diff: number) => () => {
        onChange([new Date(start.getTime() + diff), new Date(end.getTime() + diff)]);
    };

    if (value[0] instanceof Date && value[1] instanceof Date) {
        const days = Math.abs(differenceInDays(value[0], value[1]));
        const diff = Math.abs(differenceInMilliseconds(value[0], value[1]));

        const months = Math.floor(days / 30);
        const label = months ? (months === 1 ? '1 month' : `${months} months`) : days === 1 ? '1 day' : `${days} days`;

        return (
            days >= 1 && (
                <Box
                    sx={{
                        gridColumn: '2',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                    }}
                >
                    <Button onClick={onClick(value[0], value[1], -diff)}>previous {label}</Button>
                    <Button onClick={onClick(value[0], value[1], +diff)}>next {label}</Button>
                </Box>
            )
        );
    }

    return null;
};

export const DateRangePickerField: FCNC<IDateRangePickerFieldProps> = ({
    stacked,
    shortcuts = [],
    placeholders,
    required = false,
    readonly,
    labelBoxSx,
    tooltip,
    label,
    id,
    value,
    onChange,
    maxDate,
    minDate,
    testId,
}) => {
    const isReadonly = useFieldsReadonly(readonly);

    // only show individual errors if range picker is required
    const [startError, setStartError] = useState(required && value[0] === null);
    const [endError, setEndError] = useState(required && value[1] === null);

    const shortcutsItems = shortcuts.map((shortcut) => DATE_RANGE_PICKER_SHORTCUT[shortcut]);
    let shortcutSelected: DateRangePickerShortcut | null = null;
    const fixedValue: DateRange<Date> = [null, null];

    const handleChange = (date: DateRange<Date>, ctx: any) => {
        const [start, end] = date;

        if (required) {
            setStartError(!start);
            setEndError(!end);
        }

        // check date validity normalized to start of day
        if (
            start &&
            ((minDate && startOfDay(start) < startOfDay(minDate)) ||
                (maxDate && startOfDay(start) > startOfDay(maxDate)))
        ) {
            setStartError(true);
        } else {
            setStartError(false);
        }

        if (
            end &&
            ((minDate && startOfDay(end) < startOfDay(minDate)) || (maxDate && startOfDay(end) > startOfDay(maxDate)))
        ) {
            setEndError(true);
        } else {
            setEndError(false);
        }

        if (ctx && ctx.shortcut) {
            shortcutSelected =
                DateRangePickerShortcutStringToEnumTable[
                    ctx.shortcut.label as keyof typeof DateRangePickerShortcutStringToEnumTable
                ];
        } else {
            shortcutSelected = null;
        }
    };

    const handleAccept = (date: DateRange<Date>) => {
        let [start, end] = date;

        if (start !== null && end !== null) {
            start = startOfDay(start);
            end = endOfDay(end);
            if (minDate) {
                if (start < minDate) {
                    start = minDate;
                }

                if (end < minDate) {
                    end = minDate;
                }
            }
        }

        if (required) {
            setStartError(!start);
            setEndError(!end);
        }

        onChange([start, end], shortcutSelected);
    };

    const getErrorStatusForDateField = useMemo(
        () => (position: RangePosition | undefined) => {
            if (required && position) {
                return position === 'start' ? startError : endError;
            }
            return false;
        },
        [required, startError, endError]
    );

    if (isReadonly) {
        return (
            <ReadOnly
                testId={testId}
                label={label}
                value={`${value[0] instanceof Date ? value[0].toLocaleString() : ''} - ${
                    value[1] instanceof Date ? value[1]?.toLocaleString() : ''
                }`}
            />
        );
    }

    if (required && label) {
        label = `${label} *`;
    }

    if (value?.[0] instanceof Date) {
        fixedValue[0] = value[0];
    }
    if (value?.[1] instanceof Date) {
        fixedValue[1] = value[1];
    }

    return (
        <Box
            sx={
                stacked
                    ? { display: 'flex', flexDirection: 'column', width: '100%' }
                    : { display: 'flex', alignItems: 'center', width: '100%' }
            }
        >
            {label ? (
                <Box component="label" htmlFor={id}>
                    <Box sx={{ width: 160, ...labelBoxSx }}>{renderTooltipLabel({ tooltip, label })}</Box>
                </Box>
            ) : null}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiDateRangePicker
                    data-testid={testId}
                    localeText={{ start: placeholders?.[0] ?? 'Start Date', end: placeholders?.[1] ?? 'End Date' }}
                    value={fixedValue}
                    // onAccept is triggered when start and end dates are committed
                    // onChange is triggered whenever any number in the start or end dates are changed
                    onAccept={handleAccept}
                    // the context is only returned through onChange. Context contains whether the user
                    // pressed on the shortcut or not. When shortcut is pressed, save the shortcut selected and
                    // return it through onAccept immediately. This is used for example by the dashboard to save the shortcut
                    // to local storage, to be able to recompute start and dates correctly upon restore
                    onChange={handleChange}
                    maxDate={maxDate}
                    minDate={minDate}
                    slotProps={{
                        shortcuts: {
                            items: shortcutsItems,
                            sx: {
                                '.MuiChip-root': {
                                    fontSize: 'inherit',
                                },
                            },
                        },
                        fieldRoot: {
                            width: '100%',
                        },
                        fieldSeparator: {
                            display: 'none',
                        },
                        textField: ({ position }) => ({
                            sx: {
                                '>.MuiFormLabel-root:not(.MuiFormLabel-filled):not(.Mui-focused)': { top: -9 },
                            },
                            inputProps: {
                                'data-testid': `${testId}${position === 'end' ? '-end' : '-start'}`,
                            },
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Calendar id="obviously-not-an-icon" />
                                    </InputAdornment>
                                ),
                            },
                            error: getErrorStatusForDateField(position),
                            helperText: getErrorStatusForDateField(position) && required ? 'Required' : undefined,
                        }),
                    }}
                    slots={{
                        toolbar: CustomDateRangePickerToolbar as FC<DateRangePickerToolbarProps<Date>>,
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};
