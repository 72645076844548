import { api } from '../../../api';
import { ApiResponse, PageResult } from '../../Utilities';
import { UserNotificationViewModel } from '../../ViewModels';
import { GetUserNotificationParams } from './Params';

/**
 * Class representing the UserNotification API.
 */
export class UserNotificationApi {
    private route = '/UserNotification';

    /**
     * Gets the user notification.
     * @param params - The parameters for the request.
     * @returns The portal notifications.
     */
    public getUserNotification = (
        params?: GetUserNotificationParams
    ): ApiResponse<PageResult<UserNotificationViewModel>> => {
        return api().get(`${this.route}`, { params });
    };

    /**
     * Acknowledges a user notification.
     * @param userId - The ID of the user.
     * @param userNotificationId - The ID of the user notification.
     * @returns The response from the API.
     */
    public acknowledgeNotification = (userId: number | undefined, userNotificationId: number) => {
        return api().post(`${this.route}/Acknowledgement`, { userId, userNotificationId });
    };
}
