import { GridRowParams } from '@mui/x-data-grid';
import { FCNC } from '../../../ui';
import { useUserGroupSearchService } from './UserGroupSearchService';
import { SearchPageTemplate, ISearchPageTemplateProps } from '../../../reusableFeatures';
import { MultiCompanySelector } from '../../MultiCompanySelector';
import { portalUserService } from '../../../services';
import { multiCompanySelectDefaultFieldConfig } from '../../../features';
import { useCallback } from 'react';

export type IUserGroupSearchPageProps = { onRowClick: (params: GridRowParams<any>) => void; onNewClick: () => void };

const CustomRightHeader: ISearchPageTemplateProps['CustomRightHeader'] = ({ onChange, viewModel }) => {
    const field = multiCompanySelectDefaultFieldConfig;

    const value = viewModel.getValueWithKey(field.dataBindingKey) as number[] | undefined;

    const updateSearchQuery = useCallback(
        (companyIds: number[]) => {
            onChange((vm) => vm.setValueWithKey(field.dataBindingKey, companyIds), field.dataBindingKey);
        },
        [onChange, field]
    );

    return <MultiCompanySelector value={value ?? []} onChange={updateSearchQuery} />;
};

export const UserGroupSearchPage: FCNC<IUserGroupSearchPageProps> = ({ onRowClick, onNewClick }) => {
    const userGroupService = useUserGroupSearchService();
    const showMultiCompanySelector = !!portalUserService.getCompanyChildren().length;

    return (
        <>
            <SearchPageTemplate
                CustomRightHeader={showMultiCompanySelector ? CustomRightHeader : undefined}
                testId="user-group-datagrid"
                entityType="User Group"
                searchService={userGroupService}
                onRowClick={onRowClick}
                onNewClick={onNewClick}
                createPermission="ManageUserSettings.CreateUserGroup"
            />
        </>
    );
};
