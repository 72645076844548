import { IDocumentViewModel } from '../../services/Document/Api';
import { factory } from './DocumentAmountsViewModelFactory';
import { BigNumber } from 'bignumber.js';
import { getOriginalDocument, setOriginalDocumentIfNeeded } from './originalDocumentRepository';
import { hasAnyFieldInvolvedInCalculationChanged } from './hasAnyFieldInvolvedInCalculationChanged';
import { IAmountChargeOrAllowance } from './IDocumentAmountsViewModel';
import { isBusinessDocTypeSupported } from './isBusinessDocTypeSupported';

function sumChargesAndAllowances(
    charges: IAmountChargeOrAllowance[],
    appliedToField: IAmountChargeOrAllowance['appliedToField'] = 'total'
): BigNumber {
    return charges
        .filter((charge) => charge.appliedToField === appliedToField)
        .reduce((acc, charge) => acc.plus(charge.amount), new BigNumber(0));
}

const isVATField = (field: IAmountChargeOrAllowance) => field.name === 'VAT';

const roundBigNumber = (n: BigNumber): BigNumber => n.decimalPlaces(2);

export function calculateCurrentDocumentTotals(document: IDocumentViewModel): IDocumentViewModel {
    const vm = factory.createDocumentAmountsViewModel(document);
    const lineItemAmounts = vm.getLineItemAmounts();

    const lineItemTotals = lineItemAmounts
        .map((x) => new BigNumber(x.unitPrice).dividedBy(x.pricingUnitDivisor).multipliedBy(x.quantity))
        .map(roundBigNumber);

    const lineItemNetTotals = lineItemAmounts
        .map(({ chargesAndAllowances }, lineIdx) => {
            const lineTotal = lineItemTotals[lineIdx];
            return lineTotal.plus(sumChargesAndAllowances(chargesAndAllowances));
        })
        .map(roundBigNumber);

    const subtotal = roundBigNumber(
        lineItemNetTotals.reduce<BigNumber>((acc, lineNetTotal) => acc.plus(lineNetTotal), new BigNumber(0))
    );

    const sumDocumentCharges = sumChargesAndAllowances(vm.getChargesAndAllowances());
    const total = roundBigNumber(subtotal.plus(sumDocumentCharges));

    const lineItemsTotalAmountDue = lineItemAmounts.reduce(
        (acc, { chargesAndAllowances }) => acc.plus(sumChargesAndAllowances(chargesAndAllowances, 'amountDue')),
        new BigNumber(0)
    );

    const documentAmountDue = sumChargesAndAllowances(vm.getChargesAndAllowances(), 'amountDue');

    const amountDue = roundBigNumber(
        total.plus(lineItemsTotalAmountDue.plus(documentAmountDue)).minus(vm.getAmountPaid())
    );

    const lineItemsTotalVATAmount = lineItemAmounts.reduce(
        (acc, { chargesAndAllowances }) => acc.plus(sumChargesAndAllowances(chargesAndAllowances.filter(isVATField))),
        new BigNumber(0)
    );

    const documentVATAmount = sumChargesAndAllowances(vm.getChargesAndAllowances().filter(isVATField));

    const totalVAT = roundBigNumber(lineItemsTotalVATAmount.plus(documentVATAmount));

    return vm.setCalculatedFields({
        total: Number(total.toFixed(2)),
        subtotal: Number(subtotal.toFixed(2)),
        totalVAT: totalVAT.isZero() ? null : Number(totalVAT.toFixed(2)),
        amountDue: Number(amountDue.toFixed(2)),
        lineItemTotals: lineItemTotals.map((lineTotal, lineIdx) => ({
            total: Number(lineTotal.toFixed(2)),
            netTotal: Number(lineItemNetTotals[lineIdx].toFixed(2)),
        })),
    });
}

function setOriginalDocumentTotalsOnCurrentDocument(document: IDocumentViewModel) {
    const originalDocumentVM = factory.createDocumentAmountsViewModel(getOriginalDocument());
    const newDocumentVM = factory.createDocumentAmountsViewModel(document);

    return newDocumentVM.setCalculatedFields(originalDocumentVM.getCalculatedFields());
}

export function calculateDocumentTotals(document: IDocumentViewModel): IDocumentViewModel {
    // we can't do anything with document types that we don't support
    if (!isBusinessDocTypeSupported(document)) {
        return document;
    }

    setOriginalDocumentIfNeeded(document);

    // we are required to not calculate totals until a field involved in the total calculation has changed.
    // but we can't just return the current document unchanged, or buggy behavior might occur. so instead
    // we need to update it with the totals on the original document. this ensures we always display the
    // original totals until the calculation is needed.
    if (!hasAnyFieldInvolvedInCalculationChanged(document)) {
        return setOriginalDocumentTotalsOnCurrentDocument(document);
    }

    return calculateCurrentDocumentTotals(document);
}
