import { EarlyAccessAttemptError, InvalidResultError, WorkflowValidationError } from './WorkflowValidationErrors';
import { WorkflowValidationResult } from './WorkflowValidationResult';
import isInteger from 'lodash/isInteger';

export class WorkflowValidationResultCollector {
    private _result: string | null = null;

    private _explanation: string | null = null;

    private _threadId: number | null = null;

    private _roleId: number | null = null;

    private _userIds: number[] | null = null;

    private _userGroupIds: number[] | null = null;

    private _workflowInstanceCustomData: object | null = null;

    private _workflowTaskCustomData: object | null = null;

    getResults(): WorkflowValidationResult {
        if (this._result === null) {
            throw new WorkflowValidationError('`result` was not set.');
        }

        const userIds = this._userIds !== null ? Array.from(new Set(this._userIds)).join(',') : null;

        const userGroupIds = this._userGroupIds !== null ? Array.from(new Set(this._userGroupIds)).join(',') : null;

        return {
            result: this._result,
            explanation: this._explanation,
            threadId: this._threadId,
            roleId: this._roleId,
            workflowInstanceCustomData: this._workflowInstanceCustomData,
            workflowTaskCustomData: this._workflowTaskCustomData,
            userIds,
            userGroupIds,
        };
    }

    get result() {
        if (this._result === null) {
            throw new EarlyAccessAttemptError('result');
        }

        return this._result;
    }

    set result(value: string) {
        if (typeof value !== 'string') {
            throw new InvalidResultError({ property: 'result', expected: 'string' });
        }

        if (!value.length) {
            throw new InvalidResultError({ property: 'result', expected: 'non-empty string' });
        }

        this._result = value;
    }

    get explanation() {
        if (this._explanation === null) {
            throw new EarlyAccessAttemptError('explanation');
        }

        return this._explanation;
    }

    set explanation(value: string) {
        if (typeof value !== 'string') {
            throw new InvalidResultError({ property: 'explanation', expected: 'string' });
        }

        if (!value.length) {
            throw new InvalidResultError({ property: 'explanation', expected: 'non-empty string' });
        }

        this._explanation = value;
    }

    private isValidId(id: number) {
        if (!isInteger(id) || id < 0) {
            return false;
        }

        return true;
    }

    get threadId() {
        if (this._threadId === null) {
            throw new EarlyAccessAttemptError('threadId');
        }

        return this._threadId;
    }

    set threadId(value: number) {
        if (!this.isValidId(value)) {
            throw new InvalidResultError({ property: 'threadId' });
        }

        this._threadId = value;
    }

    get roleId() {
        if (this._roleId === null) {
            throw new EarlyAccessAttemptError('roleId');
        }

        return this._roleId;
    }

    set roleId(value: number) {
        if (!this.isValidId(value)) {
            throw new InvalidResultError({ property: 'roleId' });
        }

        this._roleId = value;
    }

    get userIds() {
        if (this._userIds === null) {
            throw new EarlyAccessAttemptError('userIds');
        }

        return this._userIds;
    }

    set userIds(ids: number[]) {
        if (!Array.isArray(ids)) {
            throw new InvalidResultError({ property: 'userIds', expected: 'array' });
        }

        if (!ids.length) {
            throw new InvalidResultError({ property: 'userIds', expected: 'non-empty array' });
        }

        ids.forEach((value, idx) => {
            if (!this.isValidId(value)) {
                throw new InvalidResultError({ property: 'userIds', index: idx });
            }
        });

        this._userIds = ids;
    }

    get userGroupIds() {
        if (this._userGroupIds === null) {
            throw new EarlyAccessAttemptError('userGroupIds');
        }

        return this._userGroupIds;
    }

    set userGroupIds(ids: number[]) {
        if (!Array.isArray(ids)) {
            throw new InvalidResultError({ property: 'userGroupIds', expected: 'array' });
        }

        if (!ids.length) {
            throw new InvalidResultError({ property: 'userGroupIds', expected: 'non-empty array' });
        }

        ids.forEach((value, idx) => {
            if (!this.isValidId(value)) {
                throw new InvalidResultError({ property: 'userGroupIds', index: idx });
            }
        });

        this._userGroupIds = ids;
    }

    private isEmptyObject(obj: object) {
        if (typeof obj !== 'object') {
            return true;
        }

        if (obj == null) {
            return true;
        }

        return Object.keys(obj).length === 0;
    }

    get workflowInstanceCustomData() {
        if (this._workflowInstanceCustomData === null) {
            throw new EarlyAccessAttemptError('workflowInstanceCustomData');
        }

        return this._workflowInstanceCustomData;
    }

    set workflowInstanceCustomData(customData: object) {
        if (this.isEmptyObject(customData)) {
            throw new InvalidResultError({ property: 'workflowInstanceCustomData', expected: 'non-empty object' });
        }

        this._workflowInstanceCustomData = customData;
    }

    get workflowTaskCustomData() {
        if (this._workflowTaskCustomData === null) {
            throw new EarlyAccessAttemptError('workflowTaskCustomData');
        }

        return this._workflowTaskCustomData;
    }

    set workflowTaskCustomData(customData: object) {
        if (this.isEmptyObject(customData)) {
            throw new InvalidResultError({ property: 'workflowTaskCustomData', expected: 'non-empty object' });
        }

        this._workflowTaskCustomData = customData;
    }
}
