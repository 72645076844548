import { createUseCRUDService, ICRUDService } from '../../../reusableFeatures';
import { CompanyService, ICompanyViewModel, IState } from '../../../services';
import { validateData } from '../../../ui';

interface IUpdateCompanyFormFields {
    id: number | null;
    name: string;
    shortName: string | null;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
    fax: string;
    dunsNumber: string;
    contactID: number;
    contactName: string;
    contactAddress1: string;
    contactAddress2: string;
    contactAddress3: string;
    contactCity: string;
    contactCountry: string;
    contactState: string;
    contactPostalCode: string;
    contactPhone: string;
    contactFax: string;
    contactEmail: string;
    confirmContactEmail: string;
    taxId?: string;
}

export class NewAccountProfileCRUDService implements ICRUDService<number, IUpdateCompanyFormFields> {
    private service = new CompanyService();

    constructor(private readonly stateList: Array<IState>) {}

    get defaultState(): IUpdateCompanyFormFields {
        return {
            id: null,
            name: '',
            shortName: '',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            phone: '',
            fax: '',
            dunsNumber: '',
            contactID: 0,
            contactName: '',
            contactAddress1: '',
            contactAddress2: '',
            contactAddress3: '',
            contactCity: '',
            contactState: '',
            contactPostalCode: '',
            contactCountry: '',
            contactPhone: '',
            contactFax: '',
            contactEmail: '',
            confirmContactEmail: '',
            taxId: '',
        };
    }

    get key(): string {
        return 'AccountProfile';
    }

    get invalidateKeys(): string[] {
        return [''];
    }

    private validateEntity(state: IUpdateCompanyFormFields): void {
        validateData((errors) => {
            if (state.contactEmail.trim() !== state.confirmContactEmail.trim()) {
                errors.push(
                    'At least one email does not match the corresponding re-entered email. Re-entered email addresses must match their corresponding email'
                );
            }

            if (!state.name.trim().length) {
                errors.push("Main Contact - Company's name is required");
            }

            if (state.shortName && state.shortName.trim().length > 5) {
                errors.push('Short Name must be less than or equal to 5 characters');
            }

            if (!state.address1.trim().length) {
                errors.push('Main Contact - Address Line 1 is required');
            }

            if (!state.city.trim().length) {
                errors.push('Main Contact - City is required');
            }

            if (!state.state.trim().length && this.stateList.filter((v) => v.Country === state.country).length > 0) {
                errors.push('Main Contact - State / Province / Region is required');
            }

            if (!state.country.trim().length) {
                errors.push('Main Contact - Country is required');
            }

            if (!state.phone.trim().length) {
                errors.push('Main Contact - Phone Number is required');
            }

            if (!state.postalCode.trim().length) {
                errors.push('Main Contact - ZIP / Postal Code is required');
            }

            if (!state.contactName.trim().length) {
                errors.push("Support Contact - Contact's Name is required");
            }

            if (!state.contactAddress1.trim().length) {
                errors.push('Support Contact - Address Line 1 is required');
            }

            if (!state.contactCity.trim().length) {
                errors.push('Support Contact - City is required');
            }

            if (
                !state.contactState.trim().length &&
                this.stateList.filter((v) => v.Country === state.contactCountry).length > 0
            ) {
                errors.push('Support Contact - State / Province / Region is required');
            }

            if (!state.contactPostalCode.trim().length) {
                errors.push('Support Contact -  ZIP / Postal Code is required');
            }

            if (!state.contactPhone.trim().length) {
                errors.push('Support Contact - Phone Number is required');
            }

            if (!state.contactEmail.trim().length) {
                errors.push('Support Contact - Email is required');
            }

            if (!state.confirmContactEmail.trim().length) {
                errors.push('Support Contact - Email Confirmation is required');
            }
        });
    }

    private transformFromICompanyViewModelToIUpdateCompanyFormFields(
        userData: ICompanyViewModel
    ): IUpdateCompanyFormFields {
        return {
            id: userData.ID,
            taxId: userData.TIN,
            name: userData.Name,
            shortName: userData.ShortName,
            address1: userData.Address1,
            address2: userData.Address2,
            address3: userData.Address3,
            city: userData.City,
            state: userData.State,
            postalCode: userData.PostalCode,
            country: userData.Country,
            phone: userData.Phone,
            fax: userData?.Fax ?? '',
            dunsNumber: userData?.DUNSNumber,
            contactID: userData?.Contacts[0]?.ID ?? 0,
            contactName: userData?.Contacts[0]?.Name ?? '',
            contactAddress1: userData?.Contacts[0]?.Address1 ?? '',
            contactAddress2: userData?.Contacts[0]?.Address2 ?? '',
            contactAddress3: userData?.Contacts[0]?.Address3 ?? '',
            contactCity: userData?.Contacts[0]?.City ?? '',
            contactState: userData?.Contacts[0]?.State ?? '',
            contactPostalCode: userData?.Contacts[0]?.PostalCode ?? '',
            contactCountry: userData?.Contacts[0]?.Country ?? '',
            contactPhone: userData?.Contacts[0]?.Phone ?? '',
            contactFax: userData?.Contacts[0]?.Fax ?? '',
            contactEmail: userData?.Contacts[0]?.Email ?? '',
            confirmContactEmail: userData?.Contacts[0]?.Email ?? '',
        };
    }

    private transformFromIUpdateCompanyFormFieldsToICompanyViewModel(
        model: IUpdateCompanyFormFields
    ): Partial<ICompanyViewModel> {
        return {
            ID: model.id as number,
            Name: model.name,
            ShortName: model.shortName?.trim() ?? '',
            Address1: model.address1,
            Address2: model.address2,
            Address3: model.address3,
            City: model.city,
            State: model.state,
            PostalCode: model.postalCode,
            Country: model.country,
            Phone: model.phone,
            Fax: model?.fax,
            DUNSNumber: model.dunsNumber,
            TIN: model.taxId,
            // The id allows the backend to identify what exact contact should updated
            Contacts: [
                {
                    ID: model.contactID,
                    Name: model?.contactName ?? '',
                    Address1: model?.contactAddress1 ?? '',
                    Address2: model?.contactAddress2 ?? '',
                    Address3: model?.contactAddress3 ?? '',
                    City: model?.contactCity ?? '',
                    State: model?.contactState ?? '',
                    PostalCode: model?.contactPostalCode ?? '',
                    Country: model?.contactCountry ?? '',
                    Phone: model?.contactPhone ?? '',
                    Fax: model?.contactFax ?? '',
                    Email: model?.contactEmail ?? '',
                },
            ],
        };
    }

    async fetchEntity(entityId: number): Promise<IUpdateCompanyFormFields> {
        const data = await this.service.getCompany(entityId);
        data.Contacts = data.Contacts.filter((c) => c.Type === 1);
        return this.transformFromICompanyViewModelToIUpdateCompanyFormFields(data)!;
    }

    async createEntity(): Promise<number> {
        throw new Error('Method Not Implemented yet');
    }

    async updateEntity(id: number, updatedCompanyData: IUpdateCompanyFormFields): Promise<void> {
        this.validateEntity(updatedCompanyData);
        await this.service.updateCompany(
            id,
            this.transformFromIUpdateCompanyFormFieldsToICompanyViewModel(updatedCompanyData)
        )!;
    }
}

export const useNewAccountProfileCRUDService = createUseCRUDService(NewAccountProfileCRUDService);
