import { IMenuSettings } from './types';
import subNavPaths from './subNavPaths';
import parseBusinessDocumentVisibility from './parseBusinessDocumentVisibility';
import parseWorkflowVisibility from './parseWorkflowVisibility';
import { IRoutes } from '../../../transcepta-types';
import { DashboardMenu } from '../../../../types';

const menuVisibilityMapper = (menuSettings: IMenuSettings, routes: IRoutes, dashboardMenus: DashboardMenu[]) => {
    const items = Object.keys(subNavPaths).reduce((acc, path) => acc.concat(subNavPaths[path]), []);

    return {
        ...items,
        DASHBOARD_OVERVIEW: dashboardMenus.includes(DashboardMenu.Overview),
        DASHBOARD_STRAIGHT_THROUGH_PROCESSING: dashboardMenus.includes(DashboardMenu.StraightThroughProcessing),
        DASHBOARD_SUPPLIERS: dashboardMenus.includes(DashboardMenu.Suppliers),
        DASHBOARD_INVOICES: dashboardMenus.includes(DashboardMenu.Invoices),
        DASHBOARD_SPEND: dashboardMenus.includes(DashboardMenu.Spend),
        TRANSACTIONS_ASNS: parseBusinessDocumentVisibility(menuSettings, 9),
        TRANSACTIONS_INVENTORY: parseBusinessDocumentVisibility(menuSettings, 6),
        TRANSACTIONS_INVOICES: parseBusinessDocumentVisibility(menuSettings, 1),
        TRANSACTIONS_ACKLNS: parseBusinessDocumentVisibility(menuSettings, 3),
        TRANSACTIONS_PURCHASE_ORDERS: parseBusinessDocumentVisibility(menuSettings, 2),
        TRANSACTIONS_CONSUMPTION: parseBusinessDocumentVisibility(menuSettings, 7),
        TRANSACTIONS_RECEIVING: parseBusinessDocumentVisibility(menuSettings, 8),
        TRANSACTIONS_SIM_DOCUMENT: parseBusinessDocumentVisibility(menuSettings, 12),
        PARK_LOT_ALL_WORKFLOWS: menuSettings.WorkflowsEnabled?.filter((item) => item.Active).length > 1,
        PARK_LOT_NONSTOCK_INVOICES: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_NONSTOCK_INVOICES?.workflowTemplateUI ?? ''
        ),
        PARK_LOT_COUNTER_POS: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_COUNTER_POS?.workflowTemplateUI ?? ''
        ),
        PARK_LOT_INVOICE_CODING: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_INVOICE_CODING?.workflowTemplateUI ?? ''
        ),
        PARK_LOT_DOCUMENT_APPROVAL: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_DOCUMENT_APPROVAL?.workflowTemplateUI ?? ''
        ),
        PARK_LOT_GLCODING: parseWorkflowVisibility(menuSettings, routes.PARK_LOT_GLCODING?.workflowTemplateUI ?? ''),
        PARK_LOT_GLCODING_RELEASE: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_GLCODING_RELEASE?.workflowTemplateUI ?? ''
        ),
        PARK_LOT_PARKED_DOCUMENTS: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_PARKED_DOCUMENTS?.workflowTemplateUI ?? ''
        ),
        PARK_LOT_PO_LINE_NUMS: parseWorkflowVisibility(
            menuSettings,
            routes.PARK_LOT_PO_LINE_NUMS?.workflowTemplateUI ?? ''
        ),
        ASNS: parseBusinessDocumentVisibility(menuSettings, 9),
        CONSUMPTION_ADVICES: parseBusinessDocumentVisibility(menuSettings, 8),
        INVENTORY_ADVICES: parseBusinessDocumentVisibility(menuSettings, 6),
        INVOICES: parseBusinessDocumentVisibility(menuSettings, 1),
        PURCHASE_ORDERS: parseBusinessDocumentVisibility(menuSettings, 2),
        PURCHASE_ORDERS_ACKLNS: parseBusinessDocumentVisibility(menuSettings, 3),
        RECEIVING_ADVICES: parseBusinessDocumentVisibility(menuSettings, 7),
        SIM_DOCUMENTS: parseBusinessDocumentVisibility(menuSettings, 12),
    };
};

export default menuVisibilityMapper;
