import { useState } from 'react';

import { differenceInCalendarDays, subDays } from 'date-fns';
import { ConfirmationDialog } from '../../ui';
import { Stack, Typography } from '@mui/material';
import { dashboardFormatDate } from './dashboardFormatDate';

type DateRange = {
    startDate: Date;
    endDate: Date;
};

function isValidDateRange(range: DateRange, maxDays: number): boolean {
    const diffInCalendarDays = differenceInCalendarDays(range.endDate, range.startDate);

    return diffInCalendarDays <= maxDays;
}

function formatDate(date: Date): string {
    return dashboardFormatDate(date);
}

type DialogMode = 'InvalidRange' | 'ValidRange';

type DialogState = {
    isOpen: boolean;
    mode: DialogMode;
    dateRange: DateRange | null;
    maxExportDays: number;
    minStartDate: Date;
    resolvers: {
        confirm: () => void;
        cancel: () => void;
    };
};

const defaultDialogState: DialogState = {
    isOpen: false,
    mode: 'ValidRange',
    dateRange: null,
    maxExportDays: Infinity,
    minStartDate: new Date(0),
    resolvers: {
        confirm: () => {},
        cancel: () => {},
    },
};

export function useReportExportDialog({ reportTitle }: { reportTitle: string }) {
    const [dialogState, setDialogState] = useState<DialogState>(defaultDialogState);

    const resetDialogState = () => setDialogState(defaultDialogState);

    const closeDialog = () => {
        dialogState.resolvers.cancel();
        resetDialogState();
    };

    const confirmExport = () => {
        dialogState.resolvers.confirm();
        resetDialogState();
    };

    const openDialog = (mode: DialogMode, dateRange: DateRange, maxExportDays: number) => {
        return new Promise<{ confirmed: boolean; confirmedDateRange?: DateRange }>((resolve) => {
            const minStartDate = Number.isFinite(maxExportDays)
                ? subDays(dateRange.endDate, maxExportDays)
                : defaultDialogState.minStartDate;

            const confirm = () =>
                resolve({
                    confirmed: true,
                    confirmedDateRange: {
                        startDate: mode === 'InvalidRange' ? minStartDate : dateRange.startDate,
                        endDate: dateRange.endDate,
                    },
                });
            const cancel = () => resolve({ confirmed: false });

            setDialogState({
                mode,
                isOpen: true,
                dateRange,
                maxExportDays,
                minStartDate,
                resolvers: {
                    confirm,
                    cancel,
                },
            });
        });
    };

    const validateAndOpenDialog = async (dateRange: DateRange, maxExportDays: number | null) => {
        if (!maxExportDays) {
            return openDialog('ValidRange', dateRange, Infinity);
        }

        const isValidRange = isValidDateRange(dateRange, maxExportDays);

        if (!isValidRange) {
            return openDialog('InvalidRange', dateRange, maxExportDays);
        }

        return openDialog('ValidRange', dateRange, maxExportDays);
    };

    const ExportDialog = () => {
        const { dateRange, maxExportDays, mode, minStartDate } = dialogState;

        const content = () => {
            if (!dateRange) {
                return null;
            }

            const displayedStartDate = mode === 'InvalidRange' ? minStartDate : dateRange.startDate;

            const formattedStartDate = formatDate(displayedStartDate);
            const formattedEndDate = formatDate(dateRange.endDate);

            const displayClarificationMsg = mode === 'InvalidRange';

            return (
                <Stack spacing={2}>
                    <Stack direction="row">
                        <Typography>
                            Do you wish to export data from{' '}
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                {formattedStartDate}
                            </Typography>{' '}
                            to{' '}
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                {formattedEndDate}
                            </Typography>
                            ?
                        </Typography>
                    </Stack>
                    {displayClarificationMsg && (
                        <Typography color="warning.600">
                            Note: Export limited to{' '}
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                {maxExportDays}
                            </Typography>{' '}
                            days from{' '}
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                {formattedStartDate}
                            </Typography>{' '}
                            due to large quantity of data
                        </Typography>
                    )}
                </Stack>
            );
        };

        const title = `Export Data - ${reportTitle}`;

        return (
            <ConfirmationDialog
                open={dialogState.isOpen}
                title={title}
                prompt={content()}
                confirm={{
                    buttonTitle: 'Export',
                    onClick: confirmExport,
                }}
                reject={{
                    buttonTitle: 'Cancel',
                    onClick: closeDialog,
                }}
            />
        );
    };

    return {
        validateAndOpenDialog,
        ExportDialog,
    };
}
