import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createUseSearchService,
    createTextField,
    createDropdownField,
} from '../../../reusableFeatures';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import {
    IUserGroupGrid,
    IUserGroupSearchItems,
    orderBy,
    portalUserService,
    UserGroupService,
    backendServices,
} from '../../../services';
import { TOOLTIP_CELL_MODE } from '../../../ui';
import { paginate, conditionalOptions } from '../../../utils';
import { DeleteUserGroupDialog } from './DeleteUserGroupDialog';
import { multiCompanySelectDefaultFieldConfig } from '../../../features';

export class UserGroupSearchService implements ISearchService<IUserGroupSearchItems> {
    private companyApi = new backendServices.Apis.CompanyApi();

    private userGroupService = new UserGroupService();

    private companyChildren = portalUserService.getCompanyChildren();

    private multiCompanyIsEnabled = this.companyChildren.length > 0;

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IUserGroupSearchItems>> {
        const currentCompanyId = portalUserService.getCurrentCompanyId();

        const currentCompany = await this.companyApi.getCompany(currentCompanyId);

        const childCompanyIds = portalUserService.getCompanyChildren().map((c) => c.value);

        const childCompanyIdsAndParentCompany = [currentCompanyId, ...childCompanyIds];

        const gridColumns: GridColumns = [
            { field: 'name', headerName: 'Name', flex: 1, sortable: true },
            ...conditionalOptions(this.multiCompanyIsEnabled, [
                {
                    field: 'companyId',
                    headerName: 'Company Name',
                    renderCell: (params: GridRenderCellParams): string => {
                        if (params.value === currentCompanyId) {
                            return currentCompany.data[0]?.Name ?? '';
                        }
                        return this.companyChildren.find((c) => c.value === params.value)?.label ?? '';
                    },
                    flex: 2,
                    sortable: false,
                },
            ]),
            { field: 'description', headerName: 'Description', flex: 3, sortable: true },
            { field: 'parentUserGroupName', headerName: 'Parent User Group', flex: 1, sortable: false },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                renderCell: (params: GridRenderCellParams) =>
                    params.cellMode === TOOLTIP_CELL_MODE ? null : <DeleteUserGroupDialog id={+params.id} />,
                sortable: false,
            },
        ];

        const searchFormFieldColumns = [
            [
                createTextField({
                    id: 'name',
                    label: 'User Group Name',
                    defaultValue: '',
                    dataBindingKey: 'name',
                }),
                createTextField({
                    label: 'Description',
                    defaultValue: '',
                    dataBindingKey: 'description',
                }),
                createTextField({
                    label: 'Parent User Group',
                    defaultValue: '',
                    dataBindingKey: 'parentUserGroupName',
                }),
                ...conditionalOptions(this.multiCompanyIsEnabled, [
                    createDropdownField({
                        ...multiCompanySelectDefaultFieldConfig,
                        initialValue: childCompanyIdsAndParentCompany,
                    }),
                ]),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: false,
            isCriteriaEmpty: (_, filledFields) => {
                if (
                    this.multiCompanyIsEnabled &&
                    !filledFields.includes(multiCompanySelectDefaultFieldConfig.dataBindingKey)
                ) {
                    return true;
                }

                return false;
            },
        };
    }

    private filterUserGroupGrid = (
        grid: IUserGroupGrid[],
        name: string | null,
        description: string | null,
        parentUserGroupName: string | null
    ) => {
        let filteredGrid = [...grid];

        if (name) {
            filteredGrid = filteredGrid.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));
        }

        if (description) {
            filteredGrid = filteredGrid.filter((item) =>
                item.description.toLowerCase().includes(description.toLowerCase())
            );
        }

        if (parentUserGroupName) {
            filteredGrid = filteredGrid.filter((item) =>
                item.parentUserGroupName?.toLowerCase().includes(parentUserGroupName.toLowerCase())
            );
        }

        return filteredGrid;
    };

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IUserGroupSearchItems>,
        searchRequest: ISearchRequest<IUserGroupSearchItems>
    ): Promise<ISearchResponse> {
        const { MultiCompanySelect = [] } = searchRequest.searchQuery.state;

        const companyID = portalUserService.getCurrentCompanyId();
        const response = await this.userGroupService.getUserGroupsGrid(
            companyID,
            orderBy(searchRequest.sort),
            this.multiCompanyIsEnabled && !!MultiCompanySelect.length ? MultiCompanySelect.join(',') : undefined
        );
        if (!response) {
            return {
                pageResults: [],
                totalResultCount: 0,
            };
        }

        const filtered = this.filterUserGroupGrid(
            response,
            searchRequest.searchQuery.state.name,
            searchRequest.searchQuery.state.description,
            searchRequest.searchQuery.state.parentUserGroupName
        );

        return {
            pageResults: paginate(filtered, searchRequest.pageSize, searchRequest.pageNumber),
            totalResultCount: filtered.length,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for user group search');
    }

    get key(): string {
        return 'UserGroupSearch';
    }
}

export const useUserGroupSearchService = createUseSearchService(UserGroupSearchService);
