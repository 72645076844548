import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { NumericUtil } from '../../../../transcepta-common';
import { IAccountValue, IValue, ISegment } from '../types';
import { SearchBox } from './SearchBox';
import SelectVirtualized from './SelectVirtualized';
import { createFetchCompanyGLAccountsDataArgs } from './fetchData';
import { DocumentEditRules } from '../../../../../features';
import { useDropdownOptionsRepository } from '../../../../../features/DocumentEditRules';
import _ from 'lodash';
import { InputField } from '../../../Molecules';

function useObserver() {
    const [isVisible, setIsVisible] = useState(false);

    const debouncedSetIsVisible = useMemo(() => {
        return _.debounce(setIsVisible, 200);
    }, [setIsVisible]);

    const onObserve = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;

            debouncedSetIsVisible(entry.isIntersecting);
        },
        [debouncedSetIsVisible]
    );

    const observer = useMemo(
        () =>
            new IntersectionObserver(onObserve, {
                root: null,
                rootMargin: '150px',
                threshold: 1.0,
            }),
        [onObserve]
    );

    const ref = useCallback(
        (element: HTMLElement | null) => {
            observer.disconnect();

            if (!element) {
                return;
            }

            observer.observe(element);
        },
        [observer]
    );

    return { ref, isVisible };
}

interface IProps {
    /**
     *  Is used to generate the query key
     */
    companyId: number;

    /**
     * Used to update the redux store when a field is edited
     */
    segmentKey: string;

    /**
     * Used to filter the correct subset of data for the account
     */
    segmentName?: string;

    /**
     * Along with segmentName is used to filter out the subset of data for the account
     */
    depth?: string;

    /**
     * Current value to set the dropdown value
     */
    value: IValue | null;

    /**
     * Feature not implemented, always true
     */
    editMode: boolean;

    /**
     * Display a clickable icon to clear the dropdown
     */
    isClearable?: boolean;

    /**
     * Add a custom CSS style to this control
     */
    className?: string;

    /**
     * Stand for the setup for this account dropdown
     */
    segment: ISegment;

    /**
     * At least one segment must be the default when Depth = 1 and has a SegmentName not empty
     */
    isDefaultAccount: boolean;

    /**
     * When the user selects a dropdown item (account) it is used to populate the next dropdown with the Children property
     */
    selectedAccount?: IAccountValue;

    /**
     * It is used to populate the initial values dropdown when the property "Value" has data
     */
    initialAccountRef: React.MutableRefObject<IAccountValue | undefined>;

    /**
     * This events is execute it when the user selects (clicks) a dropdown
     */
    onAccountChange: (account: IAccountValue, segmentKey?: string) => void;

    /**
     * If this dropdown is not focused, just render a field to display the value (vs. the dropdown)
     */
    unfocused?: boolean;
}

function hasMessage(error: unknown): error is { message: unknown } {
    return !!(error && typeof error === 'object' && 'message' in error);
}

/**
 * Creates a dropdown component of type Company GL Account
 * * This component can handle multiple accounts in cascade
 */
const SelectGLAccounts: FC<IProps> = ({
    companyId,
    segmentKey,
    segmentName,
    depth,
    value,
    editMode,
    isClearable = false,
    className,
    segment,
    isDefaultAccount,
    selectedAccount,
    initialAccountRef,
    onAccountChange,
    unfocused = false,
}) => {
    const { isVisible, ref } = useObserver();
    const [hasOptionsInRepository, setHasOptionsInRepository] = useState(false);

    const [filteredData, setFilteredData] = useState<IAccountValue[]>([]);
    const customQuery = useQuery<IAccountValue[] | void>(...createFetchCompanyGLAccountsDataArgs(segment, companyId));
    const { isLoading, isFetching, isError, error, data } = customQuery;

    const specificFieldKey = DocumentEditRules.useFieldKey(segment.Name);
    const sharedFieldKey = DocumentEditRules.useGLCodingAllSplitsFieldKey(segment.Name);

    const fieldKey = isDefaultAccount ? sharedFieldKey : specificFieldKey;

    const dropdownOptionsRepository = useDropdownOptionsRepository();

    const handleOnchange = useCallback(
        (account: IAccountValue) => {
            onAccountChange(account, segmentKey);
        },
        [onAccountChange, segmentKey]
    );

    const getValueLabelFromFilteredData = useCallback(
        (v: IValue | null) => {
            if (v !== null) {
                return filteredData.find((fd: IAccountValue) => fd.value === v.value)?.label ?? v.value;
            }
            return '';
        },
        [filteredData]
    );

    // Only check if filteredData is an array and the dropdownOptionsRepository is available
    // We shouldn't check if filteredData is empty because it can be empty and we still need to set the dropdown options
    // This is because filteredData can be empty when the user selects an account that doesn't have children
    useEffect(() => {
        if (Array.isArray(filteredData) && dropdownOptionsRepository) {
            dropdownOptionsRepository.setDropdownOptions(
                fieldKey,
                filteredData.map((x) => ({ label: x.name, value: x.value }))
            );
            setHasOptionsInRepository(true);
        }
    }, [filteredData, dropdownOptionsRepository, fieldKey]);

    useEffect(() => {
        if (!data) {
            return;
        }

        if (!isVisible && hasOptionsInRepository) {
            return;
        }

        const currentDepth = parseInt(depth || '0', 10);

        // When the user selects an item, the redux store gets updated first, then re-render this component with the selected account
        // in order to populate the next dropdown
        if (selectedAccount) {
            // When is the default account we set the subset of accounts directly from the data
            if (isDefaultAccount) {
                setFilteredData(data.filter((f: IAccountValue) => f.name === segmentName && f.depth === currentDepth));
                // When is not the default account we set the subset of accounts from the property "Children" of the selected account
            } else if (selectedAccount) {
                // This guarantee that we update the next dropdown that is in cascade
                // User selects a dropdown. Dropdown will be deemed a parent if selected dropdown depth is defined, and less than current dropdown depth
                const isSelectedDropdownParent =
                    selectedAccount.depth === undefined ? false : currentDepth > selectedAccount.depth;
                const hasSegmentNameInChildren =
                    selectedAccount.children?.length > 0 &&
                    selectedAccount.children?.every((f) => f.name === segmentName);

                if (isSelectedDropdownParent && hasSegmentNameInChildren) {
                    const account = selectedAccount.children?.filter(
                        (f: IAccountValue) => f.name === segmentName && f.depth === currentDepth
                    )[0];

                    // if the selected account has children, then, these are used for the next dropdown if not set an empty array
                    if (account) {
                        setFilteredData(selectedAccount.children);

                        // See if the value matches an option, and if so, preemptively select it
                        // This will trigger updating the next dropdown in cascading dropdowns scenario
                        if (value) {
                            const selectedValue = selectedAccount.children?.find(
                                (f: IAccountValue) => f.value === value.value
                            );
                            if (selectedValue) {
                                handleOnchange(selectedValue);
                            }
                        }
                    } else {
                        setFilteredData([]);
                    }
                }
                // if the selected account doesn't have children, force clearing the next dropdown. This prevents stale data.
                else if (isSelectedDropdownParent) {
                    setFilteredData([]);
                }
            }
            return;
        }

        // When it's the initial render for each account's segment then this code is executed
        // It's start with the default account
        if (isDefaultAccount) {
            const account = data.filter(
                (f: IAccountValue) => f.name === segmentName && f.depth === currentDepth && f.value === value?.value
            )[0];

            // save the account if has a value, so it can be used in the next render with the next segment
            if (account) {
                initialAccountRef.current = account;
            }

            setFilteredData(data.filter((f) => f.name === segmentName && f.depth === currentDepth));

            // When is not the default account and the initial account helper has data
            // Then, this is used to populate the next dropdown
        } else if (initialAccountRef.current) {
            const filteredAccount = { ...initialAccountRef.current };
            const account = initialAccountRef.current.children?.filter(
                (f: IAccountValue) => f.name === segmentName && f.depth === currentDepth && f.value === value?.value
            )[0];

            // save the account if has a value, so it can be used in the next render with the next segment
            if (account) {
                initialAccountRef.current = account;
            }

            setFilteredData(
                filteredAccount.children.filter(
                    (f: IAccountValue) => f.name === segmentName && f.depth === currentDepth
                )
            );
        }
        // Reasons for missing dependencies given below:
        // initialAccountRef: This is ref hook and it is used only when the page completes the initial renders, changing this object cannot cause re-render
        // isDefaultAccount: This value it's computed in the parent component to determine the default segment, this variable can be computed at the begging of this function using the "Segment" object
        // SegmentName: it's part of the "Segment" object (we can use Segment.CompanyGLAccountSegmentName) and the value should not change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, depth, selectedAccount, value, isVisible]);

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isFetching) {
        return <>Fetching...</>;
    }

    if (isError) {
        return <>{`An error has occurred:  ${hasMessage(error) && error.message ? error.message : ''}`}</>;
    }

    const recordLimit = NumericUtil.parseNumericValue(segment.CompanyGLAccountSmallRecordLimit) ?? 1000;
    const minChars = NumericUtil.parseNumericValue(segment.CompanyGLAccountMinCharsSearch) ?? 3;

    const recordLimitReached = (filteredData as IValue[])?.length > recordLimit;
    const placeholder = recordLimitReached ? `Type ${minChars}+ chars...` : undefined;

    const Component = recordLimitReached ? SearchBox : SelectVirtualized;

    // * The first element returned here must be
    // * the InputField or Component. There is a css rule
    // * to highlight the first element next to the label
    // * if there are errors.
    return (
        <>
            {unfocused ? (
                <InputField
                    className="unselected-input"
                    initialValue={getValueLabelFromFilteredData(value)}
                    readOnly
                    editable
                    editMode={false}
                    id={value?.label}
                    fieldName={value?.label ?? 'unfocused field'}
                />
            ) : (
                <Component
                    data={filteredData}
                    minimumInputSearch={minChars}
                    value={value}
                    onChange={(account: unknown) => {
                        handleOnchange(account as IAccountValue);
                    }}
                    className={className}
                    editMode={editMode}
                    isClearable={isClearable}
                    placeholder={placeholder}
                />
            )}
            <span ref={ref} />
        </>
    );
};

export default SelectGLAccounts;
