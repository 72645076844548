import menuVisibilityMapper from './menuVisibilityMapper';
import { IMenuSettings } from './types';
import { IRoutes } from '../../../transcepta-types';
import { isFeatureEnabled } from '../../../../config';
import { DashboardMenu } from '../../../../types';

const filterVisibleSubMenus = (
    menuSettings: IMenuSettings,
    routes: IRoutes,
    isDashboardEnabled?: boolean,
    dynamicDashboardMenus: DashboardMenu[] = []
) => {
    const subMenuItems = {
        TRANSACTIONS: {
            items: [
                'TRANSACTIONS_ASNS',
                'TRANSACTIONS_CONSUMPTION',
                'TRANSACTIONS_INVENTORY',
                'TRANSACTIONS_INVOICES',
                'TRANSACTIONS_ACKLNS',
                'TRANSACTIONS_PURCHASE_ORDERS',
                'TRANSACTIONS_RECEIVING',
                'TRANSACTIONS_SIM_DOCUMENT',
            ],
            expanded: false,
        },
        PARK_LOT: {
            items: [
                'PARK_LOT_ALL_WORKFLOWS',
                'PARK_LOT_NONSTOCK_INVOICES',
                'PARK_LOT_COUNTER_POS',
                'PARK_LOT_GLCODING',
                'PARK_LOT_GLCODING_RELEASE',
                'PARK_LOT_PARKED_DOCUMENTS',
                'PARK_LOT_PO_LINE_NUMS',
                'PARK_LOT_INVOICE_CODING',
                'PARK_LOT_DOCUMENT_APPROVAL',
            ],
            expanded: false,
        },
        'DYNAMIC-DISCOUNTING': {
            items: [
                'DYNAMIC_DISCOUNT_CREATE_CAMPAIGN',
                'DYNAMIC_DISCOUNT_REPORTING',
                'DYNAMIC_DISCOUNT_MANAGE_USERS',
                'DYNAMIC_DISCOUNT_SETTINGS',
            ],
            expanded: false,
        },
        DOCUMENTS: {
            items: [
                'ASNS',
                'CONSUMPTION_ADVICES',
                'INVENTORY_ADVICES',
                'INVOICES',
                'PURCHASE_ORDERS',
                'PURCHASE_ORDERS_ACKLNS',
                'RECEIVING_ADVICES',
            ],
            expanded: false,
        },
        DASHBOARD: {
            items: [
                'DASHBOARD_OVERVIEW',
                'DASHBOARD_STRAIGHT_THROUGH_PROCESSING',
                'DASHBOARD_SUPPLIERS',
                'DASHBOARD_INVOICES',
                'DASHBOARD_SPEND',
            ],
            expanded: false,
        },
    };

    const dashboardMenus = (() => {
        if (!isDashboardEnabled) {
            return [];
        }

        if (isFeatureEnabled('ShowHardcodedDashboards')) {
            return [
                DashboardMenu.Overview,
                DashboardMenu.StraightThroughProcessing,
                DashboardMenu.Suppliers,
                DashboardMenu.Invoices,
                DashboardMenu.Spend,
            ];
        }

        return dynamicDashboardMenus;
    })();

    const visibleItemsMap = menuVisibilityMapper(menuSettings, routes, dashboardMenus);

    Object.keys(subMenuItems).map((key) => {
        subMenuItems[key].items = subMenuItems[key].items.filter((item) => {
            return visibleItemsMap[item];
        });
    });

    return subMenuItems;
};

export default filterVisibleSubMenus;
