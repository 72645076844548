import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import { BuilderOptions } from 'xml2js';
import { isFeatureEnabled } from '../../../config';
import { OldXMLBuilder, OldXMLParser } from '../../../utils';
import { IWorkflowActivityExitFlowViewModel, IWorkflowActivityViewModel, IWorkflowViewModel } from '../Api';
import {
    GLCodingUIType,
    IWorkflow,
    IWorkflowActivity,
    IWorkflowActivityExitFlow,
    IWorkflowActivityExitFlowForm,
    IWorkflowActivityForm,
    IWorkflowForm,
    WorkflowActivityGLCodingMode,
    WorkflowActivityReprocess,
} from './types';

const parseObjToXML = (obj: object): string =>
    isFeatureEnabled('XMLParserSwitch')
        ? new XMLBuilder({}).build(obj)
        : new OldXMLBuilder({
              renderOpts: { pretty: false, allowEmpty: true },
              headless: true,
          } as BuilderOptions).build(obj);

function getWorkflowReprocessFromViewModel(reprocess: IWorkflowViewModel['Reprocess']): IWorkflow['reprocess'] {
    if (!reprocess) {
        return null;
    }

    const parser = isFeatureEnabled('XMLParserSwitch')
        ? new XMLParser({ trimValues: true })
        : new OldXMLParser({ trim: true, explicitArray: false });

    try {
        const { Reprocess } = parser.parse(reprocess);
        return {
            reprocessType:
                !('CompleteInstance' in Reprocess) && !('RestartWorkflowInstance' in Reprocess)
                    ? null
                    : (Reprocess?.CompleteInstance?.Complete as string)?.toLowerCase?.() === 'true'
                    ? WorkflowActivityReprocess.Complete
                    : WorkflowActivityReprocess.Restart,
            poUploadCustomLogic: Reprocess?.POUploadCustomLogicSP ?? null,
            ...((Reprocess?.CompleteInstance?.Complete as string)?.toLowerCase?.() === 'true'
                ? {
                      workflowTaskResult: Reprocess?.CompleteInstance?.WorkflowTaskResult,
                      workflowTaskCustomExplanation: Reprocess?.CompleteInstance?.WorkflowTaskCustomExplanation,
                      workflowInstanceResult: Number(Reprocess?.CompleteInstance?.WorkflowInstanceResult),
                      workflowInstanceCustomExplanation: Reprocess?.CompleteInstance?.WorkflowInstanceCustomExplanation,
                      createNewWorkflowInstance: null,
                  }
                : {
                      workflowTaskResult: Reprocess?.RestartWorkflowInstance?.WorkflowTaskResult,
                      workflowTaskCustomExplanation: Reprocess?.RestartWorkflowInstance?.WorkflowTaskCustomExplanation,
                      workflowInstanceResult: null,
                      workflowInstanceCustomExplanation: null,
                      createNewWorkflowInstance:
                          (Reprocess?.RestartWorkflowInstance?.CreateNewWorkflowInstance as string)?.toLowerCase?.() ===
                          'true',
                  }),
        };
    } catch (e) {
        return null;
    }
}

export function getWorkflowCustomDataFromViewModel(
    customData: IWorkflowViewModel['CustomData']
): IWorkflow['customData'] {
    if (!customData) {
        return null;
    }

    const parser = isFeatureEnabled('XMLParserSwitch')
        ? new XMLParser({ trimValues: true })
        : new OldXMLParser({ trim: true, explicitArray: false });

    try {
        const { WorkflowCustomData: CustomData } = parser.parse(customData);
        return {
            glCodingOptions: (CustomData?.GLCodingOptions?.GLCodingType as string)?.length
                ? {
                      glCodingType: CustomData?.GLCodingOptions?.GLCodingType,
                      glCodingSeparator: CustomData?.GLCodingOptions?.GLCodingSeparator,
                      glCodingUIType: CustomData?.GLCodingOptions?.GLCodingUIType
                          ? GLCodingUIType[CustomData?.GLCodingOptions?.GLCodingUIType]
                          : null,
                      glAccountStructure: Number(CustomData?.GLCodingOptions?.GLAccountStructure),
                  }
                : null,
            workflowMessage: CustomData?.WorkflowMessage,
            sendRejectionCommentsToOriginator:
                (CustomData?.SendRejectionCommentsToOriginator as string)?.toLowerCase?.() === 'true',
            includeApprovalsInHistory: (CustomData?.IncludeApprovalsInHistory as string)?.toLowerCase?.() === 'true',
            includeEditsInHistory: (CustomData?.IncludeEditsInHistory as string)?.toLowerCase?.() === 'true',
            includeRestartWorkflowInHistory:
                (CustomData?.IncludeRestartWorkflowInHistory as string)?.toLowerCase?.() === 'true',
            includeWorkflowCreatedInHistory:
                (CustomData?.IncludeWorkflowCreatedInHistory as string)?.toLowerCase?.() === 'true',
            includeWorkflowClosedInHistory:
                (CustomData?.IncludeWorkflowClosedInHistory as string)?.toLowerCase?.() === 'true',
            includeWorkflowTaskCreatedInHistory:
                (CustomData?.IncludeWorkflowTaskCreatedInHistory as string)?.toLowerCase?.() === 'true',
            includeWorkflowTaskClosedInHistory:
                (CustomData?.IncludeWorkflowTaskClosedInHistory as string)?.toLowerCase?.() === 'true',
            includeWorkflowSystemChangeInHistory:
                (CustomData?.IncludeWorkflowSystemChangeInHistory as string)?.toLowerCase?.() === 'true',
            enableBulkReassignment: (CustomData?.EnableBulkReassignment as string)?.toLowerCase?.() === 'true',
            enableBulkRejection: (CustomData?.EnableBulkRejection as string)?.toLowerCase?.() === 'true',
            enableBulkApproval: (CustomData?.EnableBulkApproval as string)?.toLowerCase?.() === 'true',
            enableInvoiceCreation: (CustomData?.EnableInvoiceCreation as string)?.toLowerCase?.() === 'true',
            enableInvoiceCreationOCROption:
                (CustomData?.EnableInvoiceCreationOCROption as string)?.toLowerCase?.() === 'true',
            enableInvoiceCreationAIOption:
                (CustomData?.EnableInvoiceCreationAIOption as string)?.toLowerCase?.() === 'true',
            hideReasonForApprovalDialog:
                (CustomData?.HideReasonForApprovalDialog as string)?.toLowerCase?.() === 'true',
            userGroupEnabledForFirstApprover:
                (CustomData?.UserGroupEnabledForFirstApprover as string)?.toLowerCase?.() === 'true',
        } as IWorkflow['customData'];
    } catch (e) {
        return null;
    }
}

function getWorkflowActivityReprocessFromViewModel(
    reprocess: IWorkflowActivityViewModel['Reprocess']
): IWorkflowActivity['reprocess'] {
    if (!reprocess) {
        return null;
    }

    const parser = isFeatureEnabled('XMLParserSwitch')
        ? new XMLParser({ trimValues: true })
        : new OldXMLParser({ trim: true, explicitArray: false });

    try {
        const { Reprocess } = parser.parse(reprocess);
        return {
            reprocessType:
                (Reprocess?.CompleteTask?.Complete as string)?.toLowerCase?.() === 'true'
                    ? WorkflowActivityReprocess.Complete
                    : WorkflowActivityReprocess.Restart,
            taskResult: Reprocess?.CompleteTask?.TaskResult || null,
            taskCustomExplanation: Reprocess?.CompleteTask?.TaskCustomExplanation || null,
        };
    } catch (e) {
        return null;
    }
}

function getWorkflowActivityCustomDataFromViewModel(
    customData: IWorkflowActivityViewModel['CustomData']
): IWorkflowActivity['customData'] {
    if (!customData) {
        return null;
    }

    const parser = isFeatureEnabled('XMLParserSwitch')
        ? new XMLParser({ trimValues: true })
        : new OldXMLParser({ trim: true, explicitArray: false });

    try {
        const { WorkflowActivityConfiguration: CustomData } = parser.parse(customData);
        const UIOptions: { Action?: string; Label?: string }[] = (() => {
            if (Array.isArray(CustomData?.UIOptions?.UIOption)) {
                return CustomData?.UIOptions?.UIOption;
            }
            if (CustomData?.UIOptions?.UIOption) {
                return [CustomData?.UIOptions?.UIOption];
            }
            return [];
        })();
        const saveUIOption = UIOptions.find((UIOption) => UIOption?.Action === 'Save');
        const approveUIOption = UIOptions.find((UIOption) => UIOption?.Action === 'FleetprideNonInvApprove');
        const rejectUIOption = UIOptions.find((UIOption) => UIOption?.Action === 'Reject');

        return {
            glCoding: {
                glCodingMode: CustomData?.GLCoding?.Mode
                    ? WorkflowActivityGLCodingMode[CustomData?.GLCoding?.Mode]
                    : null,
                addsUp: (CustomData?.GLCoding?.Validations?.AddsUp as string)?.toLowerCase?.() === 'true',
                oneSegmentFilled:
                    (CustomData?.GLCoding?.Validations?.OneSegmentFilled as string)?.toLowerCase?.() === 'true',
            },
            documentEditability: {
                documentEditabilityEnabled:
                    (CustomData?.DocumentEditability?.Enabled as string)?.toLowerCase?.() === 'true',
                lineAddDeleteEnabled:
                    (CustomData?.DocumentEditability?.LineAddDeleteEnabled as string)?.toLowerCase?.() === 'true',
            },
            buttons: {
                save: {
                    enabled: !!saveUIOption,
                    label: saveUIOption?.Label || '',
                },
                reject: {
                    enabled: !!rejectUIOption,
                    label: rejectUIOption?.Label || '',
                },
                approve: {
                    enabled: !!approveUIOption,
                    label: approveUIOption?.Label || '',
                },
            },
            enableVBValidations: (CustomData?.EnableVBValidations as string)?.toLowerCase?.() === 'true',
        } as IWorkflowActivity['customData'];
    } catch (e) {
        console.error(e);
        return null;
    }
}

export function transformWorkflowActivityExitFlowFromViewModel(
    data: IWorkflowActivityExitFlowViewModel
): IWorkflowActivityExitFlow {
    return {
        id: data.Id,
        result: data.Result,
        flowInfo: data.FlowInfo,
        workflowActivityId: data.WorkflowActivityId,
        flowType: data.FlowType,
        flowType_US: data.FlowType_US,
    };
}

export function transformWorkflowActivityFromViewModel(data: IWorkflowActivityViewModel): IWorkflowActivity {
    return {
        id: data.Id,
        workflowId: data.WorkflowId,
        name: data.Name,
        step: data.Step.toString(),
        activityType: data.ActivityType,
        activityType_US: data.ActivityType_US,
        service: data.Service,
        setupLogicType: data.SetupLogicType,
        setupLogicType_US: data.SetupLogicType_US,
        setupLogic: data.SetupLogic,
        setupLogicJavaScriptFilePath: data.SetupJavaScriptFilePath,
        activityLogicType: data.ActivityLogicType,
        activityLogicType_US: data.ActivityLogicType_US,
        activityLogic: data.ActivityLogic,
        activityLogicJavaScriptFilePath: data.ActivityJavaScriptFilePath,
        endEventResult: data.EndEventResult,
        endEventResult_US: data.EndEventResult_US,
        reassignmentRule: data.ReassignmentRule,
        reassignmentRule_US: data.ReassignmentRule_US,
        timeoutCount: data.TimeoutCount?.toString() ?? '',
        timeoutUnits: data.TimeoutUnits,
        timeoutUnits_US: data.TimeoutUnits_US,
        roleTypeId: data.RoleTypeId,
        workflowFieldDictionaryTemplateId: data.WorkflowFieldDictionaryTemplateId?.toString() ?? '',
        reprocess: getWorkflowActivityReprocessFromViewModel(data.Reprocess),
        customData: getWorkflowActivityCustomDataFromViewModel(data.CustomData),
        glCodingEditability: data.GlCodingEditability,
        documentEditActive: data.DocumentEditActive,
        documentLineAddDeleteActive: data.DocumentLineAddDeleteActive,
        approvalType: data.ApprovalType,
        approvalType_US: data.ApprovalType_US,
        defaultApproverUserEmail: data.DefaultApproverUserEmail,
        workflowAdminUserGroupName: data.WorkflowAdminUserGroupName,
        defaultApproverUserId: data.DefaultApproverUserId,
        defaultApproverUserGroupId: data.DefaultApproverUserGroupId,
        workflowActivityExitFlows: data.WorkflowActivityExitFlows.map(transformWorkflowActivityExitFlowFromViewModel),
    };
}

export function transformWorkflowFromViewModel(data: IWorkflowViewModel): IWorkflow {
    return {
        id: data.Id,
        name: data.Name,
        ruleName: data.RuleName,
        shortName: data.ShortName,
        workflowTemplateUI: data.WorkflowTemplateUI,
        version: data.Version.toString(),
        businessDocType: data.BusinessDocType,
        businessDocType_US: data.BusinessDocType_US,
        reprocess: getWorkflowReprocessFromViewModel(data.Reprocess),
        profileType: data.ProfileType,
        profileType_US: data.ProfileType_US,
        profileId: data.ProfileId,
        ruleOrdinal: data.RuleOrdinal,
        entryConditionLogicType: data.EntryConditionLogicType,
        entryConditionLogicType_US: data.EntryConditionLogicType_US,
        entryConditionLogic: data.EntryConditionLogic,
        entryConditionLogicJavaScriptFilePath: data.JavaScriptFilePath,
        active: data.Active,
        customData: getWorkflowCustomDataFromViewModel(data.CustomData),
        approvalType: data.ApprovalType,
        approvalType_US: data.ApprovalType_US,
        workflowActivities: data.WorkflowActivities.map(transformWorkflowActivityFromViewModel),
        workflowGroupUsers: data.WorkflowGroupUsers.map((gUser) => ({
            id: gUser.Id,
            userId: gUser.UserId,
            workflowId: gUser.WorkflowId,
        })),
    };
}

export function transformWorkflowActivityExitFlowToViewModel(
    data: IWorkflowActivityExitFlowForm
): IWorkflowActivityExitFlowViewModel {
    return {
        Id: data.id,
        Result: data.result,
        FlowInfo: data.flowInfo,
        WorkflowActivityId: data.workflowActivityId,
        FlowType: data.flowType!,
        FlowType_US: data.flowType_US,
    } as IWorkflowActivityExitFlowViewModel;
}

function transformWorkflowActivityReprocessToViewModel(
    reprocess: IWorkflowActivity['reprocess']
): IWorkflowActivityViewModel['Reprocess'] {
    if (!reprocess || reprocess.reprocessType === null) {
        return null;
    }

    const reprocessObj = {
        Reprocess: (() => {
            const obj: any = {};

            if (reprocess.reprocessType === WorkflowActivityReprocess.Complete) {
                obj.CompleteTask = {
                    Complete: 'true',
                };

                const taskResult = reprocess.taskResult?.trim();
                if (taskResult) {
                    obj.CompleteTask.TaskResult = taskResult;
                }

                const taskCustomExplanation = reprocess.taskCustomExplanation?.trim();
                if (taskCustomExplanation) {
                    obj.CompleteTask.TaskCustomExplanation = taskCustomExplanation;
                }
            } else {
                obj.RestartTask = 'true';
            }

            return obj;
        })(),
    };

    return parseObjToXML(reprocessObj);
}

const removeEmpty = <T extends object>(obj: T): T => {
    const nonEmptyEntries = Object.entries(obj).filter(([, value]) => value !== undefined);

    return Object.fromEntries(nonEmptyEntries) as T;
};

function transformWorkflowActivityCustomDataToViewModel(
    customData: IWorkflowActivity['customData']
): IWorkflowActivityViewModel['CustomData'] {
    if (!customData) {
        return null;
    }

    const workflowActivityConfiguration = {
        GLCoding: (() => {
            if (!customData.glCoding) {
                return undefined;
            }

            if (customData.glCoding.glCodingMode == null) {
                return undefined;
            }

            const obj: any = {};

            // GL Coding Mode is a required field, it will not be null
            obj.Mode = WorkflowActivityGLCodingMode[customData.glCoding.glCodingMode];

            if (
                customData.glCoding.glCodingMode === WorkflowActivityGLCodingMode.AlwaysVisible ||
                customData.glCoding.glCodingMode === WorkflowActivityGLCodingMode.PODependent
            ) {
                obj.Validations = {
                    AddsUp: customData.glCoding.addsUp ? 'True' : 'False',
                    OneSegmentFilled: customData.glCoding.oneSegmentFilled ? 'True' : 'False',
                };
            }

            return obj;
        })(),
        DocumentEditability: (() => {
            if (!customData.documentEditability) {
                return undefined;
            }

            const obj: any = {};

            obj.Enabled = customData?.documentEditability.documentEditabilityEnabled ? 'True' : 'False';

            if (customData.documentEditability.documentEditabilityEnabled) {
                obj.LineAddDeleteEnabled = customData.documentEditability.lineAddDeleteEnabled ? 'True' : 'False';
            }

            return obj;
        })(),
        UIOptions: (() => {
            if (!customData.buttons) {
                return undefined;
            }

            const UIOptions: { Action: string; Label: string; Result?: string }[] = [];
            if (customData.buttons.save.enabled) {
                UIOptions.push({
                    Action: 'Save',
                    Label: customData.buttons.save.label || 'Save',
                });
            }
            if (customData.buttons.approve.enabled) {
                UIOptions.push({
                    Action: 'FleetprideNonInvApprove',
                    Label: customData.buttons.approve.label || 'Approve',
                    Result: 'Approved',
                });
            }
            if (customData.buttons.reject.enabled) {
                UIOptions.push({
                    Action: 'Reject',
                    Label: customData.buttons.reject.label || 'Reject',
                    Result: 'Rejected',
                });
            }
            if (UIOptions.length) {
                return { UIOption: UIOptions };
            }
            return undefined;
        })(),
        EnableVBValidations: customData.enableVBValidations ? 'True' : 'False',
    };

    return parseObjToXML({ WorkflowActivityConfiguration: removeEmpty(workflowActivityConfiguration) });
}

export function transformWorkflowActivityToViewModel(data: IWorkflowActivityForm): IWorkflowActivityViewModel {
    return {
        Id: data.id,
        WorkflowId: data.workflowId,
        Name: data.name,
        Step: Number(data.step),
        ActivityType: data.activityType!,
        ActivityType_US: data.activityType_US,
        Service: data.service,
        SetupLogicType: data.setupLogicType,
        SetupLogicType_US: data.setupLogicType_US,
        SetupLogic: data.setupLogic,
        ActivityLogicType: data.activityLogicType,
        ActivityLogicType_US: data.activityLogicType_US,
        ActivityLogic: data.activityLogic,
        EndEventResult: data.endEventResult,
        EndEventResult_US: data.endEventResult_US,
        ReassignmentRule: data.reassignmentRule,
        ReassignmentRule_US: data.reassignmentRule_US,
        TimeoutCount: data.timeoutCount ? Number(data.timeoutCount) : null,
        TimeoutUnits: data.timeoutUnits,
        TimeoutUnits_US: data.timeoutUnits_US,
        RoleTypeId: data.roleTypeId,
        WorkflowFieldDictionaryTemplateId: data.workflowFieldDictionaryTemplateId
            ? Number(data.workflowFieldDictionaryTemplateId)
            : null,
        Reprocess: transformWorkflowActivityReprocessToViewModel(data.reprocess),
        CustomData: transformWorkflowActivityCustomDataToViewModel(data.customData),
        GlCodingEditability: data.glCodingEditability,
        DocumentEditActive: data.documentEditActive,
        DocumentLineAddDeleteActive: data.documentLineAddDeleteActive,
        ApprovalType: data.approvalType,
        ApprovalType_US: data.approvalType_US,
        DefaultApproverUserId: data.defaultApproverUserId,
        DefaultApproverUserGroupId: data.defaultApproverUserGroupId,

        DefaultApproverUserEmail: data.defaultApproverUserEmail,
        WorkflowAdminUserGroupName: data.workflowAdminUserGroupName,
        WorkflowActivityExitFlows: data.workflowActivityExitFlows.map(transformWorkflowActivityExitFlowToViewModel),
    } as IWorkflowActivityViewModel;
}

function transformWorkflowReprocessToViewModel(reprocess: IWorkflow['reprocess']): IWorkflowViewModel['Reprocess'] {
    if (!reprocess) {
        return null;
    }

    if (reprocess.reprocessType === null && !reprocess.poUploadCustomLogic?.trim()) {
        return null;
    }

    const workflowReprocessObj = {
        Reprocess: (() => {
            const obj: any = {};

            const poUploadCustomLogic = reprocess.poUploadCustomLogic?.trim();
            if (poUploadCustomLogic) {
                obj.POUploadCustomLogicSP = poUploadCustomLogic;
            }

            if (reprocess.reprocessType === WorkflowActivityReprocess.Complete) {
                obj.CompleteInstance = {
                    Complete: 'true',
                };

                const taskResult = reprocess.workflowTaskResult?.trim();
                if (taskResult) {
                    obj.CompleteInstance.WorkflowTaskResult = taskResult;
                }

                const taskCustomExplanation = reprocess.workflowTaskCustomExplanation?.trim();
                if (taskCustomExplanation) {
                    obj.CompleteInstance.WorkflowTaskCustomExplanation = taskCustomExplanation;
                }

                const instanceResult = reprocess.workflowInstanceResult;
                if (instanceResult) {
                    obj.CompleteInstance.WorkflowInstanceResult = instanceResult;
                }

                const instanceCustomExplanation = reprocess.workflowInstanceCustomExplanation?.trim();
                if (instanceCustomExplanation) {
                    obj.CompleteInstance.WorkflowInstanceCustomExplanation = instanceCustomExplanation;
                }
            } else if (reprocess.reprocessType === WorkflowActivityReprocess.Restart) {
                obj.RestartWorkflowInstance = { Restart: 'true' };

                obj.RestartWorkflowInstance.CreateNewWorkflowInstance = reprocess.createNewWorkflowInstance
                    ? 'true'
                    : 'false';

                const taskResult = reprocess.workflowTaskResult?.trim();
                if (taskResult) {
                    obj.RestartWorkflowInstance.WorkflowTaskResult = taskResult;
                }

                const taskCustomExplanation = reprocess.workflowTaskCustomExplanation?.trim();
                if (taskCustomExplanation) {
                    obj.RestartWorkflowInstance.WorkflowTaskCustomExplanation = taskCustomExplanation;
                }
            }

            return obj;
        })(),
    };

    return parseObjToXML(workflowReprocessObj);
}

function transformWorkflowCustomDataToViewModel(customData: IWorkflow['customData']): IWorkflowViewModel['CustomData'] {
    if (!customData) {
        return null;
    }

    const workflowCustomDataObj = {
        WorkflowCustomData: {
            GLCodingOptions: customData.glCodingOptions
                ? {
                      GLCodingType: customData.glCodingOptions.glCodingType,
                      GLCodingSeparator: customData.glCodingOptions.glCodingSeparator.trim(),
                      GLCodingUIType:
                          customData.glCodingOptions.glCodingUIType !== null
                              ? GLCodingUIType[customData.glCodingOptions.glCodingUIType]
                              : null,
                      GLAccountStructure: customData.glCodingOptions.glAccountStructure,
                  }
                : '',
            WorkflowMessage: customData.workflowMessage.trim(),
            SendRejectionCommentsToOriginator: customData.sendRejectionCommentsToOriginator ? 'true' : 'false',
            IncludeApprovalsInHistory: customData.includeApprovalsInHistory ? 'true' : 'false',
            IncludeEditsInHistory: customData.includeEditsInHistory ? 'true' : 'false',
            IncludeRestartWorkflowInHistory: customData.includeRestartWorkflowInHistory ? 'true' : 'false',
            IncludeWorkflowCreatedInHistory: customData.includeWorkflowCreatedInHistory ? 'true' : 'false',
            IncludeWorkflowClosedInHistory: customData.includeWorkflowClosedInHistory ? 'true' : 'false',
            IncludeWorkflowTaskCreatedInHistory: customData.includeWorkflowTaskCreatedInHistory ? 'true' : 'false',
            IncludeWorkflowTaskClosedInHistory: customData.includeWorkflowTaskClosedInHistory ? 'true' : 'false',
            IncludeWorkflowSystemChangeInHistory: customData.includeWorkflowSystemChangeInHistory ? 'true' : 'false',
            EnableBulkReassignment: customData.enableBulkReassignment ? 'true' : 'false',
            EnableBulkRejection: customData.enableBulkRejection ? 'true' : 'false',
            EnableBulkApproval: customData.enableBulkApproval ? 'true' : 'false',
            EnableInvoiceCreation: customData.enableInvoiceCreation ? 'true' : 'false',
            EnableInvoiceCreationOCROption: customData.enableInvoiceCreationOCROption ? 'true' : 'false',
            EnableInvoiceCreationAIOption: customData.enableInvoiceCreationAIOption ? 'true' : 'false',
            HideReasonForApprovalDialog: customData.hideReasonForApprovalDialog ? 'true' : 'false',
            UserGroupEnabledForFirstApprover: customData.userGroupEnabledForFirstApprover ? 'true' : 'false',
        },
    };

    return parseObjToXML(workflowCustomDataObj);
}

export function transformWorkflowToViewModel(data: IWorkflowForm): IWorkflowViewModel {
    return {
        Id: data.id,
        Name: data.name,
        RuleName: data.ruleName,
        ShortName: data.shortName,
        WorkflowTemplateUI: data.workflowTemplateUI!,
        Version: Number(data.version),
        BusinessDocType: data.businessDocType,
        BusinessDocType_US: data.businessDocType_US,
        Reprocess: transformWorkflowReprocessToViewModel(data.reprocess),
        ProfileType: data.profileType,
        ProfileType_US: data.profileType_US,
        ProfileId: data.profileId!,
        RuleOrdinal: data.ruleOrdinal,
        EntryConditionLogicType: data.entryConditionLogicType,
        EntryConditionLogicType_US: data.entryConditionLogicType_US,
        EntryConditionLogic: data.entryConditionLogic,
        Active: data.active,
        CustomData: transformWorkflowCustomDataToViewModel(data.customData),
        ApprovalType: data.approvalType,
        ApprovalType_US: data.approvalType_US,
        WorkflowActivities: data.workflowActivities.map(transformWorkflowActivityToViewModel),
        WorkflowGroupUsers: data.workflowGroupUsers.map((gUser) => ({
            Id: gUser.id,
            UserId: gUser.userId,
            WorkflowId: gUser.workflowId,
        })),
    } as IWorkflowViewModel;
}

export function parseCustomDataXML(customDataXml: string): IWorkflow['customData'] {
    return getWorkflowCustomDataFromViewModel(customDataXml);
}
