import { ChartConfiguration } from '../../Api';
import { saveAs } from 'file-saver';

// reduces array of length array.length into n equally divided pieces,
// and takes the mid point of each sub array
export function reduceArray(array: string[], n: number) {
    if (n <= 0 || n >= array.length || array === undefined || !array.length) {
        return array;
    }

    const copiedArray: string[] = [...array];
    const reducedArray: string[] = [];

    for (let i = n; i > 0; i--) {
        // divide array (such as the palette array) into n evenly distributed length arrays
        const temp: string[] = copiedArray.splice(0, Math.ceil(copiedArray.length / i));
        // pick mid points of each array
        reducedArray.push(temp[Math.floor(temp.length / 2)]);
    }

    return reducedArray;
}

export function saveAndFormatFilename(blob: Blob, title: string, configuration: Pick<ChartConfiguration, 'dateRange'>) {
    saveAs(
        blob,
        title.split(' ').join('-') +
            '_' +
            configuration.dateRange?.startDate?.toLocaleDateString('en-US') +
            '-' +
            configuration.dateRange?.endDate?.toLocaleDateString('en-US') +
            '.csv'
    );
}

// TODO: replace this with the primary color number (ex: primary.500)
export const PIE_COLORS = [
    '#06314F',
    '#0A4977',
    '#0D629E',
    '#0E6EB2',
    '#107AC6',
    '#4095D1',
    '#70AFDD',
    '#9FCAE8',
    '#CFE4F4',
    '#E7F2F9',
];

export function dashboardFormatDate(date: string | Date) {
    // date will be a date label and formatted by the backend. Old code: format(new Date(date), 'MM/dd/yy');
    return String(date);
}

// this is to support displaying a NO DATA message when the data is empty
// through noData HighCharts property
export function nullifyDataIfEmpty(
    data: any,
    nullifyDataIfEmptyObj: { optIn: boolean; xAxisPropertyKey: string | undefined }
) {
    // if dashboard graph opted out of empty data evaluation, return the data as is
    // This is specially useful for non HighCharts charts, such as Spend cards or Data tables
    if (!nullifyDataIfEmptyObj || !nullifyDataIfEmptyObj.optIn) {
        return data;
    }

    if (Array.isArray(data) && data.length > 0 && nullifyDataIfEmptyObj.optIn) {
        // grab the first object in the array and get the Object keys other than the xAxis
        const keysToCheck = Object.keys(data[0]).filter((key) => key != nullifyDataIfEmptyObj.xAxisPropertyKey);
        // iterate through the data array and check if all the keys other than the xAxis are 0
        const isDataEmpty = !data.some((d) => {
            return keysToCheck.some((key) => d[key] != null && d[key] != 0);
        });
        if (isDataEmpty) {
            return null;
        }
        return data;
    }
    return null;
}
