import { AxiosResponse } from 'axios';
import { GetUserGroupsParams } from './types';
import { IUserGroupViewModel } from './ViewModels';
import ICreatedUpdateDeleteViewModel from '../../Common/Api/ViewModels/CreatedUpdateDeleteViewModel';
import { IUserGroupViewModelData } from '../Service';
import { api } from '../../api';
import { RecursivePartial } from '../../../types';

export class UserGroupApi {
    route = '/UserGroup';

    /**
     * Retrieves the user groups for a given company.
     *
     * @param companyId - The ID of the company.
     * @param sort - Optional. The sorting criteria for the user groups.
     * @param childCompanyId - Comma delimited string of company IDs (optional).
     * @returns A promise that resolves to an AxiosResponse containing an array of IUserGroupViewModel.
     */
    public getUserGroups = (
        companyId: number,
        sort?: string,
        childCompanyId?: string
    ): Promise<AxiosResponse<IUserGroupViewModel[]>> => {
        const params: GetUserGroupsParams = { companyId, childCompanyId };
        if (sort) {
            params.$orderBy = sort;
        }
        return api().get(`${this.route}/`, { params });
    };

    public getUserGroup = (id: number, filter?: string): Promise<AxiosResponse<IUserGroupViewModel[]>> => {
        const params = {
            $filter: filter,
        };

        return api().get(`${this.route}/${id}`, { params });
    };

    public createUserGroup = (
        userGroupViewModel: RecursivePartial<IUserGroupViewModelData>
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().post(this.route, userGroupViewModel);
    };

    public updateUserGroup = (
        id: number,
        userGroupViewModel: RecursivePartial<IUserGroupViewModelData>
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().put(`${this.route}/${id}`, userGroupViewModel);
    };

    public deleteUserGroup = (id: number): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().delete(`${this.route}/${id}`);
    };
}
