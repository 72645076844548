import { useCallback, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { DocumentSearchConfigurationUtils, multiCompanySelectDefaultFieldConfig, MultiCompanySelector } from '../../..';
import {
    ISearchPageTemplateProps,
    SearchCriteriaError,
    SearchPageTemplate,
    SearchPageTemplateOnNewClick,
    SearchPageTemplateOnRowClick,
    SimpleFieldRendererViewModel,
    useDependencyWatcher,
} from '../../../reusableFeatures';
import { backendServices, portalUserService, useCurrency } from '../../../services';
import { BusinessDocType } from '../../../types';
import { Checkbox } from '../../../ui';
import { isValidDateRange } from '../../../utils';
import { useDocumentsSearchService } from './DocumentsSearchService';
import { DocumentSearchConfigurationPage } from '../../DocumentSearchConfiguration';

const CustomRightFooter: ISearchPageTemplateProps['CustomRightFooter'] = ({ onChange, viewModel }) => {
    const field = DocumentSearchConfigurationUtils.constants.includePartialMatchesDefaultFieldConfig;

    return (
        <Stack direction="row" justifyContent="space-between">
            <Box component="label" sx={{ marginRight: '0 !important', flex: '1', lineHeight: '14px' }}>
                <Checkbox
                    checked={viewModel.getValueWithKey(field.dataBindingKey) ?? false}
                    id="performContainsSearch"
                    label={field.label}
                    tooltip={{
                        message: field.description,
                        icon: 'Help',
                    }}
                    onChange={({ target }) =>
                        onChange((vm) => vm.setValueWithKey(field.dataBindingKey, target.checked), field.dataBindingKey)
                    }
                />
            </Box>
        </Stack>
    );
};

const CustomRightHeader: ISearchPageTemplateProps['CustomRightHeader'] = ({ onChange, viewModel }) => {
    const field = multiCompanySelectDefaultFieldConfig;

    const value = viewModel.getValueWithKey(field.dataBindingKey) as number[] | undefined;

    const updateSearchQuery = useCallback(
        (companyIds: number[]) => {
            onChange((vm) => vm.setValueWithKey(field.dataBindingKey, companyIds), field.dataBindingKey);
        },
        [onChange, field]
    );

    return <MultiCompanySelector value={value ?? []} onChange={updateSearchQuery} allowMultipleCompanies={false} />;
};

const EntityTypeLabelPerDocumentType: Record<BusinessDocType, string | null> = {
    [BusinessDocType.Undefined]: null,
    [BusinessDocType.Unknown]: null,
    [BusinessDocType.NullDocument]: null,
    [BusinessDocType.FunctionalACK]: null,
    [BusinessDocType.ASN]: 'ASN',
    [BusinessDocType.ASNGeneric]: 'ASN',
    [BusinessDocType.InventoryAdvice]: 'Inventory Advice',
    [BusinessDocType.Invoice]: 'Invoice',
    [BusinessDocType.PurchaseOrderAcknowledgement]: 'PO Acknowledgement',
    [BusinessDocType.PurchaseOrder]: 'Purchase Order',
    [BusinessDocType.ReceivingAdvice]: 'Receiving Advice',
    [BusinessDocType.ConsumptionAdvice]: 'Consumption Advice',
    [BusinessDocType.SIMDocument]: 'SIM Document',
};

const getEntityTypeLabel = (documentType: BusinessDocType): string | null =>
    EntityTypeLabelPerDocumentType[documentType] ?? null;

const getValidateFields = (
    searchConfiguration: backendServices.ViewModels.DocumentSearchConfigurationViewModel
): ISearchPageTemplateProps['validateFields'] => {
    return (fieldRendererViewModel: SimpleFieldRendererViewModel<DocumentSearchConfigurationUtils.FilterValues>) => {
        const filterValues = fieldRendererViewModel.state;

        try {
            DocumentSearchConfigurationUtils.validateFilterValues({
                filterValues,
                searchConfiguration,
            });
        } catch (e: unknown) {
            if (e instanceof SearchCriteriaError) {
                return e;
            }
        }

        return true;
    };
};

const onFormFieldChange: ISearchPageTemplateProps['onFormFieldChange'] = (
    fieldRendererViewModel,
    fieldRendererOnChange,
    fieldKey
) => {
    const {
        CREATED_TIME_FIELD_KEY,
        includePartialMatchesDefaultFieldConfig: { dataBindingKey: includePartialMatchesFieldKey },
    } = DocumentSearchConfigurationUtils.constants;

    if (
        fieldRendererViewModel.state[includePartialMatchesFieldKey] &&
        fieldKey !== CREATED_TIME_FIELD_KEY &&
        !isValidDateRange(fieldRendererViewModel.state[CREATED_TIME_FIELD_KEY])
    ) {
        const now = new Date();
        fieldRendererOnChange(
            () =>
                fieldRendererViewModel.setValueWithKey(CREATED_TIME_FIELD_KEY, [
                    startOfDay(subDays(now, 90)),
                    endOfDay(now),
                ]),
            CREATED_TIME_FIELD_KEY
        );
    }
};

type DocumentsSearchPageProps = {
    onRowClick: SearchPageTemplateOnRowClick<backendServices.ViewModels.DocumentSearchViewModel>;
    onNewClick?: SearchPageTemplateOnNewClick;
    documentType: number;
    isBuyerPortal: boolean;
    page?: DocumentSearchConfigurationPage;
    searchConfigurationId?: number;
};

export function DocumentsSearchPage({
    onRowClick,
    onNewClick,
    documentType,
    isBuyerPortal,
    page = DocumentSearchConfigurationPage.DOCUMENTS,
    searchConfigurationId,
}: DocumentsSearchPageProps) {
    const layoutSize = DocumentSearchConfigurationUtils.useSearchLayoutSize();

    const { data: currencyList = [], isLoading: isLoadingCurrency } = useCurrency();
    const dependenciesWatcher = useDependencyWatcher({
        dependenciesLoaded: !isLoadingCurrency,
    });

    const [configuration, setConfiguration] =
        useState<backendServices.ViewModels.DocumentSearchConfigurationViewModel | null>(null);

    const documentsSearchService = useDocumentsSearchService(
        documentType,
        isBuyerPortal,
        layoutSize,
        currencyList,
        dependenciesWatcher,
        page,
        setConfiguration,
        searchConfigurationId
    );

    const entityTypeLabel = getEntityTypeLabel(documentType);

    const gridTitle = configuration?.SearchConfiguration.SearchResult.Label ?? '';
    const gridText = isBuyerPortal ? undefined : configuration?.SearchConfiguration.SearchResult.Text ?? '';

    const validateFields = useMemo(() => {
        if (configuration == null) {
            return undefined;
        }

        return getValidateFields(configuration);
    }, [configuration]);

    const showIncludePartialMatchesCheckbox =
        configuration !== null &&
        DocumentSearchConfigurationUtils.hasContainsInFilters(configuration.SearchConfiguration.SearchFilter);

    const showMultiCompanySelector = isBuyerPortal && portalUserService.getCompanyChildren().length;

    return (
        <SearchPageTemplate
            key={page}
            entityType={entityTypeLabel ?? undefined}
            testId={isBuyerPortal ? 'transactions-datagrid' : 'documents-datagrid'}
            searchService={documentsSearchService}
            invalidateCacheOnSearchClick
            onRowClick={onRowClick}
            onNewClick={onNewClick}
            CustomRightFooter={showIncludePartialMatchesCheckbox ? CustomRightFooter : undefined}
            CustomRightHeader={showMultiCompanySelector ? CustomRightHeader : undefined}
            gridTitle={({ pluralEntityType, totalCount }) =>
                gridTitle.replace('$count$', String(totalCount)).replace('$EntityName$', pluralEntityType)
            }
            gridText={gridText}
            onFormFieldChange={onFormFieldChange}
            enableCsvExport
            defaultSort={[
                {
                    field:
                        page === DocumentSearchConfigurationPage.INVOICES_REJECTED
                            ? DocumentSearchConfigurationUtils.constants.SENDER_CONFIRMATION_FIELD_KEY
                            : page === DocumentSearchConfigurationPage.INVOICES_DELIVERED
                            ? DocumentSearchConfigurationUtils.constants.DISPATCHED_TIME_FIELD_KEY
                            : DocumentSearchConfigurationUtils.constants.CREATED_TIME_FIELD_KEY,
                    sort: 'desc',
                },
            ]}
            validateFields={validateFields}
            exportInfoMessage="Warning: The maximum number of exported items is 100,000"
        />
    );
}
