import { DashboardApi, StraightThroughProcessingReport } from '../../../Api';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';

export class StraightThroughProcessingReportService
    implements DashboardReportService<StraightThroughProcessingReport, SeriesColumnOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportWorkflowDocumentsStraightThruProcessingCounts' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<StraightThroughProcessingReport>(
            settings,
            this.storedProcedure
        );
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'GroupLabel' as const,
        };
    }

    transformVMToChartData(vm: StraightThroughProcessingReport['viewModel']) {
        return vm.map((p) => ({
            y: p.GroupValue,
            name: dashboardFormatDate(p.GroupLabel),
            label: dashboardFormatDate(p.GroupLabel),
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useStraightThroughProcessingReportService = createDashboardReportService(
    StraightThroughProcessingReportService
);
