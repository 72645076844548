import { conditionalOptions, setRelativeComplement } from '../../../utils';
import { VendorService } from './VendorService';
import { IPortalUser, portalUserService } from '../../UserService';
import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createTextField,
    createCheckboxField,
    createAutocompleteField,
    createSimpleDropdownOptions,
    createUseSearchService,
    SimpleFieldRendererViewModel,
    createRadioButtonGroup,
    createDropdownField,
    DependenciesWatcher,
} from '../../../reusableFeatures';
import { Link } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TOOLTIP_CELL_MODE } from '../../../ui';
import { getVendorSearchModeText } from '../../../utils/configurableMessages/getVendorSearchModeText';
import { InvoiceProfileType } from '../../Company/Api';
import { Typography } from '@mui/material';
import { ProfileService, ProfileType } from '../../Profile';
import { multiCompanySelectDefaultFieldConfig } from '../../../features';
import { backendServices } from '../..';

type IVendorSearchData = {
    name: string;

    vendorNumber: string;

    vendorClass: string | null;

    contactName: string;

    contactEmail: string;

    includeDisabledSuppliers: boolean;

    includeConnectedVendorsOnly: boolean;

    siteNumber: string;

    orgUnit: string | null;
} & Record<typeof multiCompanySelectDefaultFieldConfig.dataBindingKey, number[] | undefined>;

export class VendorSearchService implements ISearchService<IVendorSearchData> {
    private companyApi = new backendServices.Apis.CompanyApi();

    private vendorService = new VendorService();

    private profileService = new ProfileService();

    private multiCompanyIsEnabled = false;

    constructor(
        /**
         * Used to keep the SearchPageTemplate in a loading state until useQuery instances have finished fetching.
         */
        private readonly dependenciesWatcher: DependenciesWatcher,
        private readonly orgUnitOptions: { label: string; value: string }[]
    ) {
        this.multiCompanyIsEnabled = portalUserService.getCompanyChildren().length > 0;
    }

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IVendorSearchData>> {
        const currentUser = portalUserService.getCurrentUser('mustBeLoggedIn');
        const companyId = portalUserService.getCurrentCompanyId();

        const childCompanyIds = portalUserService.getCompanyChildren().map((c) => c.value);

        const childCompanyIdsAndParentCompany = [companyId, ...childCompanyIds];

        const { data: vendorClasses } = await this.companyApi.getVendorClass({
            companyId,
            childCompanyId: this.multiCompanyIsEnabled ? childCompanyIdsAndParentCompany.join(',') : undefined,
        });

        const profiles = await this.profileService.searchProfiles(
            ProfileType.BuyerDestination,
            companyId,
            this.multiCompanyIsEnabled ? childCompanyIdsAndParentCompany.join(',') : undefined
        );

        await this.dependenciesWatcher.waitForDependencies();

        function handleRenderLinkColumn(params: GridRenderCellParams) {
            // params.id will have the following format:
            // CompanyID-VendorID-VendorNumber
            // If any of those 3 IDs are null (missing), then vendor and supplier are not connected
            // Also if search radio button 'Search Connected Suppliers' is ON, id returned includes nulls so we won't display this link icon
            if (!params.id.toString().includes('null')) {
                if (params.cellMode === TOOLTIP_CELL_MODE) {
                    return 'Vendor and Supplier Connected';
                }

                return <Link />;
            }
            return null;
        }

        const showOrgUnit = this.orgUnitOptions.length > 0;

        return {
            gridColumns: (criteria) => {
                const { MultiCompanySelect = [] } = criteria.state;

                // if the company selector is empty, use the parent company id.
                const selectedCompanyIds =
                    MultiCompanySelect.length && this.multiCompanyIsEnabled ? MultiCompanySelect : [companyId];

                const filteredProfiles = profiles.filter((p) => selectedCompanyIds.includes(p.companyId));

                const shouldDisplayScanKeyOrNormalColumn = filteredProfiles.some(
                    (profile) => !!profile.scanKeyProfileId
                );

                return [
                    {
                        field: 'name',
                        headerName: 'Name',
                        flex: 1,
                    },
                    {
                        field: 'link',
                        headerName: '',
                        width: 40,
                        renderCell: handleRenderLinkColumn,
                        sortable: false,
                    },
                    { field: 'vendorNumber', headerName: 'Vendor Number', flex: 0.5 },
                    { field: 'vendorClass', headerName: 'Vendor Class', flex: 0.5 },
                    ...conditionalOptions(shouldDisplayScanKeyOrNormalColumn, [
                        {
                            field: 'invoiceProfileType',
                            headerName: 'CloudCapture or Normal',
                            flex: 0.5,
                            renderCell: (params: GridRenderCellParams<InvoiceProfileType>) => (
                                <Typography>
                                    {params.value === InvoiceProfileType.ScanKey ? 'CloudCapture' : 'Normal'}
                                </Typography>
                            ),
                        },
                    ]),
                    ...conditionalOptions(this.shouldUseVendorSearchWithCriteria(currentUser, criteria), [
                        { field: 'siteNumber', headerName: 'Site Number', flex: 0.5 },
                    ]),
                    { field: 'contactName', headerName: 'Contact Name', flex: 1, sortable: false },
                    { field: 'contactEmail', headerName: 'Contact Email', flex: 1.5, sortable: false },
                    ...conditionalOptions(showOrgUnit, [
                        {
                            field: 'orgUnit',
                            headerName: 'Org',
                            flex: 1,
                            sortable: false,
                            renderCell: (params: GridRenderCellParams<string | null>) =>
                                this.orgUnitOptions.find((opt) => opt.value === params.value)?.label ?? '',
                        },
                    ]),
                ];
            },
            enabledExportingMethods: [],
            searchFormFieldColumns: (criteria) => {
                const { MultiCompanySelect = [] } = criteria.state;

                // if the company selector isn't enabled, use the parent company id.
                const selectedCompanyIds = this.multiCompanyIsEnabled ? MultiCompanySelect : [companyId];

                const uniqueVendorClassNames = new Set<string>();

                const vendorClassOptions = vendorClasses
                    .filter((v) => {
                        if (!selectedCompanyIds.includes(v.CompanyId)) {
                            return false;
                        }

                        const lowerCaseVendorClassName = v.VendorClassName.toLowerCase();

                        if (uniqueVendorClassNames.has(lowerCaseVendorClassName)) {
                            return false;
                        }

                        uniqueVendorClassNames.add(lowerCaseVendorClassName);

                        return true;
                    })
                    .map((v) => v.VendorClassName);

                const leftColumn = [
                    createTextField({
                        id: 'name',
                        label: 'Name',
                        defaultValue: '',
                        dataBindingKey: 'name',
                    }),
                    createTextField({
                        id: 'vendorNumber',
                        label: 'Vendor Number',
                        defaultValue: '',
                        dataBindingKey: 'vendorNumber',
                    }),
                    ...conditionalOptions(this.multiCompanyIsEnabled, [
                        createDropdownField({
                            ...multiCompanySelectDefaultFieldConfig,
                            initialValue: childCompanyIdsAndParentCompany,
                        }),
                    ]),
                    ...conditionalOptions(vendorClasses.length > 0, [
                        createAutocompleteField({
                            id: 'vendorClass',
                            label: 'Vendor Class',
                            defaultValue: null,
                            dataBindingKey: 'vendorClass',
                            options: createSimpleDropdownOptions(vendorClassOptions),
                        }),
                    ]),
                    ...conditionalOptions(this.shouldUseVendorSearchWithCriteria(currentUser, criteria), [
                        createTextField({
                            id: 'siteNumber',
                            label: 'Site Number',
                            defaultValue: '',
                            dataBindingKey: 'siteNumber',
                        }),
                    ]),
                    ...conditionalOptions(showOrgUnit, [
                        createDropdownField({
                            id: 'orgUnit',
                            label: 'Org',
                            defaultValue: null,
                            nullOptionLabel: '',
                            dataBindingKey: 'orgUnit',
                            options: this.orgUnitOptions,
                        }),
                    ]),
                ];

                const vendorSearchModeText = getVendorSearchModeText();

                const rightColumn = [
                    createTextField({
                        id: 'contactName',
                        label: 'Contact Name',
                        defaultValue: '',
                        dataBindingKey: 'contactName',
                    }),
                    createTextField({
                        id: 'contaactEmail',
                        label: 'Contact Email',
                        defaultValue: '',
                        dataBindingKey: 'contactEmail',
                    }),
                    createCheckboxField({
                        id: 'includeDisabledSuppliers',
                        label: this.shouldUseVendorSearchWithCriteria(currentUser, criteria)
                            ? 'Include Disabled Vendors'
                            : 'Include Disabled Suppliers',
                        defaultValue: false,
                        dataBindingKey: 'includeDisabledSuppliers',
                    }),
                    ...conditionalOptions(currentUser.extraFeatures.has('VendorManagement.Enabled'), [
                        createRadioButtonGroup({
                            id: 'includeConnectedVendorsOnly',
                            dataBindingKey: 'includeConnectedVendorsOnly',
                            label: vendorSearchModeText.label,
                            defaultValue: false,
                            options: [
                                { label: vendorSearchModeText.searchVendorsText, value: false },
                                { label: vendorSearchModeText.searchSuppliersText, value: true },
                            ],
                            tooltip: vendorSearchModeText.tooltip && {
                                message: vendorSearchModeText.tooltip,
                                icon: 'Help',
                            },
                        }),
                    ]),
                ];

                return [leftColumn, rightColumn];
            },
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: false,
            isCriteriaEmpty: (criteria, filledFields) => {
                // if no company is selected, the user shouldn't be able to search
                if (
                    this.multiCompanyIsEnabled &&
                    !filledFields.includes(multiCompanySelectDefaultFieldConfig.dataBindingKey)
                ) {
                    return true;
                }

                // the API won't allow searching with only the checkbox fields selected. currently we wish
                // to force the user to "narrow" the search criteria to reduce DB load. in the future when
                // we have server side caching applied to the trading partner search endpoint, we will allow
                // searching without any criteria and remove this check.
                return (
                    setRelativeComplement(filledFields, [
                        'includeConnectedVendorsOnly',
                        'includeDisabledSuppliers',
                        // Multi-company selector should be ignored
                        multiCompanySelectDefaultFieldConfig.dataBindingKey,
                    ]).length === 0
                );
            },
        };
    }

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IVendorSearchData>,
        searchRequest: ISearchRequest<IVendorSearchData>
    ): Promise<ISearchResponse> {
        const { MultiCompanySelect = [] } = searchRequest.searchQuery.state;

        const currentUser = portalUserService.getCurrentUser('mustBeLoggedIn');
        const companyId = portalUserService.getCurrentCompanyId();

        const response = await this.vendorService.getVendorSearchGrid(
            companyId,
            true,
            searchRequest.pageSize,
            searchRequest.pageNumber,
            searchRequest.sort,
            searchRequest.searchQuery.state.name,
            searchRequest.searchQuery.state.contactName,
            searchRequest.searchQuery.state.contactEmail,
            searchRequest.searchQuery.state.vendorNumber,
            searchRequest.searchQuery.state.vendorClass,
            searchRequest.searchQuery.state.includeDisabledSuppliers,
            this.shouldUseVendorSearchWithCriteria(currentUser, searchRequest.searchQuery),
            searchRequest.searchQuery.state.siteNumber,
            this.multiCompanyIsEnabled && !!MultiCompanySelect.length ? MultiCompanySelect.join(',') : undefined,
            searchRequest.searchQuery.state.orgUnit ?? undefined
        );

        return {
            pageResults: response.items,
            totalResultCount: response.count,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for supplier search');
    }

    get key(): string {
        return 'VendorSearch'.concat(this.dependenciesWatcher.state);
    }

    private shouldUseVendorSearchWithCriteria(
        currentUser: IPortalUser,
        searchQuery: SimpleFieldRendererViewModel<IVendorSearchData>
    ) {
        return (
            !searchQuery.state.includeConnectedVendorsOnly && currentUser.extraFeatures.has('VendorManagement.Enabled')
        );
    }
}

export const useVendorSearchService = createUseSearchService(VendorSearchService);
