import { Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { CRUDTemplate } from '../../../reusableFeatures';
import { SortedSelect, TextField } from '../../../ui';
import { useNewAccountProfileCRUDService } from './NewAccountProfileCRUDService';
import { ICountry, IState, useCurrentCompanyId } from '../../../services';

interface NewAccountProfileCrudProps {
    searchPagePathName: string;
    entityType: string;
    readonly?: boolean;
    countryList?: Array<ICountry>;
    stateList?: Array<IState>;
}

interface StateOption {
    label: string;
    value: string;
}

export const NewAccountProfileCRUD: React.FC<NewAccountProfileCrudProps> = ({
    searchPagePathName,
    entityType,
    readonly = false,
    countryList = [],
    stateList = [],
}: NewAccountProfileCrudProps) => {
    const accountProfileService = useNewAccountProfileCRUDService(stateList);
    const companyID = useCurrentCompanyId();
    const history = useHistory();
    return (
        <CRUDTemplate
            testId="NewAccountProfileFormCrud"
            entityType={entityType}
            searchPagePathName={searchPagePathName}
            readPermission="AccountProfile.ViewProfile"
            editPermission="AccountProfile.EditProfile"
            service={accountProfileService}
            entityId={companyID}
            onCancel={() => {
                history.goBack();
            }}
            renderFormLayout={(field, handleFieldChange) => {
                if (readonly) {
                    return [
                        <>
                            <Typography variant="h6">Main Contact</Typography>
                            <Typography>Your company's main address and telephone number</Typography>
                            <TextField label="Your Company's Name" value={field?.name} readonly />
                            <TextField label="Your Company's Short Name" value={field?.shortName ?? ''} readonly />
                            <TextField label="Main Address Line 1" value={field?.address1} readonly />
                            <TextField label="Main Address Line 2" value={field?.address2} readonly />
                            <TextField label="Main Address Line 3" value={field?.address3} readonly />
                            <TextField label="Main City" value={field?.city} readonly />
                            <TextField label="Main State / Province / Region" value={field?.state} readonly />
                            <TextField label="Main ZIP / Postal Code" value={field?.postalCode} readonly />
                            <TextField label="Main Country" value={field?.country} readonly />
                            <TextField label="Main Phone Number" value={field?.phone} readonly />
                            <TextField label="Main Fax Number" value={field?.fax} readonly />
                            <Box sx={{ marginTop: '25px' }}>
                                <TextField label="Duns" value={field?.dunsNumber} readonly />
                            </Box>
                        </>,
                    ];
                } else {
                    const mappedCountryOptions = countryList.map((v) => ({ label: v.Name, value: v.Code }));
                    const mappedStateOptions: Array<StateOption> = stateList
                        .filter((d) => d.Country === field.country)
                        .map((v: IState) => ({
                            label: v.Name,
                            value: v.Code,
                        }));

                    const contactMappedStateOptions: Array<StateOption> = stateList
                        .filter((x) => x.Country === field.contactCountry)
                        .map((v: IState) => ({
                            label: v.Name,
                            value: v.Code,
                        }));

                    return [
                        <>
                            <Typography fontWeight="bold">Main Contact</Typography>
                            <Typography>Please enter your company's main address and telephone number</Typography>
                            <TextField
                                required
                                label="Company's Name"
                                value={field?.name}
                                onChange={(ev) => handleFieldChange('name', ev.target.value)}
                                name="name"
                                testId="name"
                            />
                            <TextField
                                label="Company's Short Name"
                                value={field?.shortName ?? ''}
                                onChange={(ev) => handleFieldChange('shortName', ev.target.value)}
                                name="shortName"
                                testId="shortName"
                            />
                            <TextField
                                required
                                label="Address Line 1"
                                value={field?.address1}
                                onChange={(ev) => handleFieldChange('address1', ev.target.value)}
                                name="address1"
                                testId="address1"
                            />
                            <TextField
                                label="Address Line 2"
                                onChange={(ev) => handleFieldChange('address2', ev.target.value)}
                                value={field?.address2}
                                name="address2"
                                testId="address2"
                            />
                            <TextField
                                label="Address Line 3"
                                onChange={(ev) => handleFieldChange('address3', ev.target.value)}
                                value={field?.address3}
                                name="address3"
                                testId="address3"
                            />
                            <TextField
                                required
                                label="City"
                                value={field?.city}
                                onChange={(ev) => handleFieldChange('city', ev.target.value)}
                                name="city"
                                testId="city"
                            />
                            {stateList.filter((v) => v.Country === field.country).length > 0 ? (
                                <SortedSelect
                                    id="account-profile-state-select"
                                    label="State / Province / Region"
                                    labelId="account-profile-state-select-label"
                                    options={mappedStateOptions}
                                    value={mappedStateOptions?.find((v) => v.value === field.state) ?? null}
                                    onChange={(val) => {
                                        handleFieldChange('state', val?.value ?? '');
                                    }}
                                    testId="state"
                                    required
                                />
                            ) : (
                                <TextField
                                    label="State / Province / Region"
                                    value={field?.state}
                                    onChange={(ev) => handleFieldChange('state', ev.target.value)}
                                    name="state"
                                    testId="state"
                                />
                            )}
                            <TextField
                                label="ZIP / Postal Code"
                                value={field?.postalCode}
                                onChange={(ev) => handleFieldChange('postalCode', ev.target.value)}
                                name="postalCode"
                                testId="postalCode"
                                required
                            />
                            <SortedSelect
                                id="account-profile-select"
                                label="Country"
                                labelId="account-profile-country-select-label"
                                options={mappedCountryOptions}
                                value={mappedCountryOptions.find((v) => v.value === field.country) ?? null}
                                onChange={(val) => {
                                    handleFieldChange('country', val?.value ?? '');
                                    handleFieldChange('state', '');
                                }}
                                testId="country"
                                required
                            />
                            <TextField
                                label="Phone Number"
                                onChange={(ev) => handleFieldChange('phone', ev.target.value)}
                                value={field?.phone}
                                name="phone"
                                testId="phone"
                                required
                            />
                            <TextField
                                label="Fax Number"
                                value={field?.fax}
                                onChange={(ev) => handleFieldChange('fax', ev.target.value)}
                                name="fax"
                                testId="fax"
                            />
                            <TextField
                                label="Tax ID Number"
                                name="taxId"
                                testId="taxId"
                                value={field?.taxId ?? ''}
                                onChange={(ev) => handleFieldChange('taxId', ev.target.value)}
                            />
                            <TextField
                                label="Duns"
                                name="dunsNumber"
                                testId="dunsNumber"
                                value={field?.dunsNumber}
                                onChange={(ev) => handleFieldChange('dunsNumber', ev.target.value)}
                            />
                        </>,
                        <>
                            <>
                                <Typography fontWeight="bold">Support Contact</Typography>
                                <Typography>
                                    If we need to contact your company regarding support issues, who should we contact?
                                </Typography>
                                <TextField
                                    label="Contact's Name"
                                    onChange={(ev) => handleFieldChange('contactName', ev.target.value)}
                                    name="contactName"
                                    testId="contactName"
                                    value={field?.contactName}
                                    required
                                />
                                <TextField
                                    label="Address Line 1"
                                    onChange={(ev) => handleFieldChange('contactAddress1', ev.target.value)}
                                    name="contactAddress1"
                                    testId="contactAddress1"
                                    value={field?.contactAddress1}
                                    required
                                />
                                <TextField
                                    label="Address Line 2"
                                    value={field?.contactAddress2}
                                    name="contactAddress2"
                                    testId="contactAddress2"
                                    onChange={(ev) => handleFieldChange('contactAddress2', ev.target.value)}
                                />
                                <TextField
                                    label="Address Line 3"
                                    value={field?.contactAddress3}
                                    name="contactAddress3"
                                    testId="contactAddress3"
                                    onChange={(ev) => handleFieldChange('contactAddress3', ev.target.value)}
                                />
                                <TextField
                                    label="City"
                                    value={field?.contactCity}
                                    onChange={(ev) => handleFieldChange('contactCity', ev.target.value)}
                                    name="contactCity"
                                    testId="contactCity"
                                    required
                                />
                                {stateList.filter((v) => v.Country === field.contactCountry).length > 0 ? (
                                    <SortedSelect
                                        id="account-profile-contact-state-select"
                                        label="State / Province / Region"
                                        labelId="account-profile-contact-state-select-label"
                                        options={contactMappedStateOptions}
                                        value={
                                            contactMappedStateOptions?.find((v) => v.value === field.contactState) ??
                                            null
                                        }
                                        onChange={(val) => handleFieldChange('contactState', val?.value ?? '')}
                                        testId="contact-state"
                                        required
                                    />
                                ) : (
                                    <TextField
                                        label="State / Province / Region"
                                        value={field?.contactState}
                                        onChange={(ev) => handleFieldChange('contactState', ev.target.value)}
                                        name="contact-state"
                                        testId="contact-state"
                                    />
                                )}
                                <TextField
                                    label="ZIP / Postal Code"
                                    value={field?.contactPostalCode}
                                    name="contactPostalCode"
                                    testId="contactPostalCode"
                                    onChange={(ev) => handleFieldChange('contactPostalCode', ev.target.value as string)}
                                    required
                                />
                                <SortedSelect
                                    id="account-profile-contact-country-select"
                                    label="Country"
                                    labelId="account-profile-contact-country-select-label"
                                    options={mappedCountryOptions}
                                    value={mappedCountryOptions?.find((v) => v.value === field.contactCountry) ?? null}
                                    onChange={(val) => {
                                        handleFieldChange('contactState', '');
                                        handleFieldChange('contactCountry', val?.value ?? '');
                                    }}
                                    testId="contact-country"
                                    required
                                />
                                <TextField
                                    label="Phone Number"
                                    name="contactPhone"
                                    value={field?.contactPhone}
                                    onChange={(ev) => handleFieldChange('contactPhone', ev.target.value as string)}
                                    testId="contactPhone"
                                    required
                                />
                                <TextField
                                    label="Fax Number"
                                    name="contactFax"
                                    value={field?.contactFax}
                                    onChange={(ev) => handleFieldChange('contactFax', ev.target.value as string)}
                                    testId="contactFax"
                                />
                                <TextField
                                    label="Email"
                                    value={field?.contactEmail}
                                    testId="contactEmail"
                                    name="contactEmail"
                                    onChange={(ev) => handleFieldChange('contactEmail', ev.target.value as string)}
                                    required
                                />
                                <TextField
                                    label="Please re-enter Email Address"
                                    value={field?.confirmContactEmail}
                                    name="contactConfirmationEmail"
                                    testId="contactConfirmationEmail"
                                    onChange={(ev) =>
                                        handleFieldChange('confirmContactEmail', ev.target.value as string)
                                    }
                                    required
                                />
                            </>
                        </>,
                    ];
                }
            }}
        />
    );
};
