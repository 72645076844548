import { useQuery } from '@tanstack/react-query';
import { backendServices, useCurrentUser } from '../../services';
import { useMemo } from 'react';
import { UserNotificationTypes } from '../../types';

export interface IUseUserNotification {
    loginPage?: boolean;
    isBuyerPortal?: boolean;
    acknowledgementId?: number;
}

/**
 * Hook to fetch user notifications based on the provided parameters.
 *
 * @param {IUseUserNotification} params - The parameters for fetching user notifications.
 * @param {boolean} [params.loginPage=false] - If true, the notification is for the login page.
 * @param {boolean} [params.isBuyerPortal=true] - If true, the notification is for the buyer portal.
 * @param {number} [params.acknowledgementId] - The ID of the acknowledgement. Used to invalidate the useQuery cache so that it gets called again when the user acknowledges a notification.
 *
 * @returns {object} The user notifications data and loading state.
 * @returns {any} returns.data - The fetched user notifications data.
 * @returns {boolean} returns.userNotificationIsLoading - The loading state of the user notifications query.
 */
export function useUserNotification({
    loginPage = false,
    isBuyerPortal = true,
    acknowledgementId,
}: IUseUserNotification) {
    const $filter = useMemo(() => {
        if (isBuyerPortal) {
            return loginPage
                ? `(Type eq ${UserNotificationTypes.BuyerPortalLogin} or Type eq ${UserNotificationTypes.BothLogin})`
                : `(Type eq ${UserNotificationTypes.BuyerPortal} or Type eq ${UserNotificationTypes.BothPortals})`;
        }
        return loginPage
            ? `(Type eq ${UserNotificationTypes.SupplierPortalLogin} or Type eq ${UserNotificationTypes.BothLogin})`
            : `(Type eq ${UserNotificationTypes.SupplierPortal} or Type eq ${UserNotificationTypes.BothPortals})`;
    }, [isBuyerPortal, loginPage]);

    const userId = useCurrentUser()?.id;
    const companyId = useCurrentUser()?.companyId;

    const { data, isLoading: userNotificationIsLoading } = useQuery(
        ['UserNotification', loginPage, isBuyerPortal, userId, companyId, acknowledgementId],
        async () => {
            const userNotificationApi = new backendServices.Apis.UserNotificationApi();
            const res = await userNotificationApi.getUserNotification({ $filter, userId, companyId });
            return res.data;
        },
        {
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    return {
        data,
        userNotificationIsLoading,
    };
}
