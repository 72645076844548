import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { isFeatureEnabled } from '../config';
import { backendServices, useCurrentCompanyId, useCurrentUser, useCurrentUserCan } from '../services';
import { DashboardMenu, DashboardSubMenu } from '../types/Enums';

const dashboardMenuOrder = [
    DashboardMenu.Overview,
    DashboardMenu.StraightThroughProcessing,
    DashboardMenu.Invoices,
    DashboardMenu.Suppliers,
    DashboardMenu.Spend,
];

export interface StructuredDashboardReportMetadata {
    [menu: number]: {
        [subMenu: string]: backendServices.ViewModels.DashboardReportMetadataViewModel[];
    };
}

// TODO: add support for childCompanyIds
export const useGetDashboardReportMetadata = ({ enabled = true }: { enabled?: boolean } = {}) => {
    const companyId = useCurrentCompanyId();
    const user = useCurrentUser('mustBeLoggedIn');

    const query = useQuery(
        ['DashboardReportMetadata', companyId, user.id],
        async () => {
            const api = new backendServices.Apis.DashboardReportMetadataApi();

            const params: backendServices.Apis.GetDashboardReportMetadataParams = {
                includeDisabled: false,
                buyerCompanyId: companyId,
                userId: user.id,
            };

            const res = await api.getDashboardReportMetadata(params);

            return res.data.map((report) => ({
                ...report,
                SubMenu: report.SubMenu ?? DashboardSubMenu.None,
            }));
        },
        {
            enabled,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    return query;
};

export const getStructuredDashboardReportMetadata = (
    dashboardReportMetadata: backendServices.ViewModels.DashboardReportMetadataViewModel[]
): StructuredDashboardReportMetadata => {
    // Group the metadata by Menu and SubMenu
    const structuredData = dashboardReportMetadata.reduce((acc, report) => {
        const menu = report.Menu;
        const subMenu = report.SubMenu || 'Default';

        if (!acc[menu]) {
            acc[menu] = {};
        }

        if (!acc[menu][subMenu]) {
            acc[menu][subMenu] = [];
        }

        acc[menu][subMenu].push(report);

        return acc;
    }, {} as StructuredDashboardReportMetadata);

    // Sort the menus according to the DashboardMenu enum order
    const sortedData: StructuredDashboardReportMetadata = {};
    dashboardMenuOrder.forEach((menu) => {
        if (structuredData[menu]) {
            sortedData[menu] = structuredData[menu];
        }
    });

    // Sort the list of metadata by the Order property within each submenu
    Object.keys(sortedData).forEach((menu) => {
        const menuKey = Number(menu) as DashboardMenu;
        Object.keys(sortedData[menuKey]).forEach((subMenu) => {
            sortedData[menuKey][subMenu].sort((a, b) => a.Order - b.Order);
        });
    });

    return sortedData;
};

/**
 * Meant to be used with LeftNav and Header Container
 */
export function useSortedDashboardMenus({ enabled }: { enabled: boolean }) {
    const isDashboardEnabled = useCurrentUserCan('Dashboard.DisplayNewDashboard');

    const isQueryEnabled = isDashboardEnabled && enabled && !isFeatureEnabled('ShowHardcodedDashboards');

    const {
        data: dashboardMetadata,
        isLoading,
        isFetching,
    } = useGetDashboardReportMetadata({
        enabled: isQueryEnabled,
    });

    const isLoadingDashboardMetadata = isLoading || isFetching;

    const dashboardMenus = useMemo(() => {
        if (!isQueryEnabled) {
            return undefined;
        }

        if (isLoadingDashboardMetadata || !dashboardMetadata) {
            return [];
        }

        const structuredDashboardReportMetadata = getStructuredDashboardReportMetadata(dashboardMetadata);

        const menus = Object.keys(structuredDashboardReportMetadata).map((m) => Number(m) as DashboardMenu);
        return menus;
    }, [dashboardMetadata, isLoadingDashboardMetadata, isQueryEnabled]);

    return { dashboardMenus, isLoadingDashboardMetadata };
}
