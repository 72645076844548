import { ChangeEvent, FC, ReactNode } from 'react';
import {
    Checkbox as MuiCheckbox,
    FormControlLabel,
    FormControl,
    Stack,
    Typography,
    StackProps,
    CheckboxProps,
    FormControlLabelProps,
} from '@mui/material';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../../ui/Form/FormControlContext';
import { ITooltipIconProps, TooltipLabel } from '../Tooltip';

export interface ICheckboxProps {
    id?: string;
    name?: string;
    label?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: any) => void;
    value?: string;
    readonly?: boolean;
    testId?: string;
    indeterminate?: boolean;
    tooltip?: ITooltipIconProps;
    height?: string;
    labelAsNode?: ReactNode;
    fullWidth?: boolean;
    stackSx?: StackProps['sx'];
    checkboxSx?: CheckboxProps['sx'];
    formControlLabelSx?: FormControlLabelProps['sx'];
    required?: boolean;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export const Checkbox: FC<ICheckboxProps> = ({
    id,
    name,
    label,
    checked,
    disabled,
    onChange,
    onClick,
    value,
    readonly,
    testId,
    indeterminate,
    tooltip,
    height = '54px',
    labelAsNode,
    fullWidth = true,
    stackSx,
    checkboxSx,
    formControlLabelSx,
    required,
    labelPlacement,
}) => {
    const isReadonly = useFieldsReadonly(readonly);

    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={checked ? 'Yes' : 'No'} />;
    }

    if (required && label) {
        label = `${label} *`;
    }

    return (
        <Stack justifyContent="center" sx={{ width: fullWidth ? 1 : 32, height: height, ...stackSx }}>
            <FormControl fullWidth={true} sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                    sx={formControlLabelSx}
                    id={id}
                    labelPlacement={labelPlacement}
                    control={
                        <MuiCheckbox
                            id={id}
                            checked={checked}
                            disabled={disabled}
                            onChange={onChange}
                            onClick={onClick}
                            name={name}
                            value={value}
                            inputProps={{ 'data-testid': testId } as any}
                            indeterminate={indeterminate}
                            sx={{
                                '&.MuiCheckbox-root': {
                                    fontSize: 24,
                                    '&:hover': {
                                        bgcolor: 'rgba(16, 122, 198, 0.12)',
                                    },
                                    '&:active': {
                                        bgcolor: 'rgba(16, 122, 198, 0.24)',
                                    },
                                    '&:focus': {
                                        border: '1px solid',
                                        borderColor: 'primary.800',
                                    },
                                },
                                ...checkboxSx,
                            }}
                        />
                    }
                    label={
                        <>
                            {labelAsNode}
                            <TooltipLabel
                                label={<Typography data-testid={`${testId}-label`}>{label}</Typography>}
                                tooltip={tooltip}
                            />
                        </>
                    }
                />
            </FormControl>
        </Stack>
    );
};
