export const ROUTE_KEY = 'route';

export const getPreservedRoute = () => {
    return localStorage.getItem(ROUTE_KEY);
};

export const setPreservedRoute = (route: string) => {
    return localStorage.setItem(ROUTE_KEY, route);
};

export const removePreservedRoute = () => {
    return localStorage.removeItem(ROUTE_KEY);
};
