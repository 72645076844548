import { BusinessDocType, RecursivePartial } from '../../../../types';
import { api } from '../../../api';
import { ApiResponse, PageResult } from '../../Utilities';
import {
    DocumentLayoutViewModel,
    DocumentSearchConfigurationViewModel,
    DocumentSearchViewModel,
    DocumentValidationResultViewModel,
    DocumentViewModel,
} from '../../ViewModels';
import {
    GetDocumentLayoutParams,
    GetDocumentSearchConfigurationParams,
    GetDocumentsForCompanyParams,
    GetDocumentsParams,
} from './Params';

export class DocumentApi {
    private route = '/Document';

    public getDocumentsForCompany = (
        params: GetDocumentsForCompanyParams
    ): ApiResponse<PageResult<DocumentViewModel>> => {
        return api().get(this.route, { params });
    };

    public getDocuments(
        params: GetDocumentsParams,
        dynamicParams?: Record<string, string | boolean | number | null>
    ): ApiResponse<PageResult<DocumentSearchViewModel>> {
        return api().get(this.route, {
            params: {
                ...params,
                ...(dynamicParams ?? {}),
            },
        });
    }

    public getDocumentByBusinessDocId(params: {
        businessDocId: string;
        businessDocType: BusinessDocType;
    }): ApiResponse<PageResult<DocumentViewModel>> {
        return api().get(this.route, { params });
    }

    public getDocumentsCSV(params: GetDocumentsParams, dynamicParams?: Record<string, string | boolean | number>) {
        const headers = {
            Accept: 'text/csv',
        };

        return api().get(`${this.route}`, {
            params: {
                ...params,
                ...(dynamicParams ?? {}),
            },
            headers,
        });
    }

    public getDocumentRevisions = (id: number): ApiResponse<DocumentViewModel[]> => {
        return api().get(`${this.route}/revisions/${id}`);
    };

    public getDocumentSearchConfiguration(
        params: GetDocumentSearchConfigurationParams
    ): ApiResponse<DocumentSearchConfigurationViewModel[]> {
        return api().get(`${this.route}/SearchConfiguration`, { params });
    }

    public getDocumentSearchConfigurationById(id: number): ApiResponse<DocumentSearchConfigurationViewModel[]> {
        return api().get(`${this.route}/SearchConfiguration/${id}`);
    }

    public getDocumentLayout(params: GetDocumentLayoutParams): ApiResponse<DocumentLayoutViewModel> {
        return api().get(`${this.route}/Layout`, { params });
    }

    postDocumentValidation = (
        params: { buyerCompanyId: number; includeSourceDocumentErrors?: boolean },
        body: DocumentViewModel
    ): ApiResponse<DocumentValidationResultViewModel[]> => {
        return api().post(`${this.route}/Validate`, body, { params });
    };

    postDocumentValidationResult = (
        params: { documentId: number },
        body: RecursivePartial<DocumentValidationResultViewModel[]>
    ): ApiResponse<void> => {
        return api().post(`${this.route}/ValidationFailureDetail`, body, { params });
    };

    getGeneratePDF({
        params,
    }: {
        params: {
            documentId: number;
        };
    }): ApiResponse<{
        FileData: string;
        FileName: string;
        FileContentType: string;
    }> {
        return api().get(`${this.route}/GeneratePDF`, {
            params,
        });
    }
}
