import { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import { Button as MuiButton, SxProps } from '@mui/material';
import useButtonStyles from './useButtonStyles';
import { Color, Variant } from './types';
import { mapToMuiVariant, toMuiColor } from './utils';
import { Theme } from '@mui/material/styles';

export interface IButtonProps {
    id?: string;
    variant?: Variant;
    color?: Color;
    type?: 'button' | 'submit' | 'reset';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    /**
     * @deprecated use sx attributes going forward
     */
    style?: CSSProperties;
    autoFocus?: boolean;
    className?: string;
    testid?: string;
    sx?: SxProps<Theme>;
    startIcon?: ReactNode;
}

export const Button: FC<IButtonProps> = ({
    id,
    variant = 'default',
    color = 'primary',
    type,
    onClick,
    disabled,
    style,
    autoFocus,
    className,
    testid,
    sx,
    children,
    startIcon,
}) => {
    const classes = useButtonStyles();

    return (
        <MuiButton
            id={id}
            data-testid={testid}
            variant={mapToMuiVariant(variant)}
            color={toMuiColor(color)}
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={className}
            style={style}
            autoFocus={autoFocus}
            classes={{
                root: classes.root,
                containedPrimary: classes.containedPrimary,
                containedSuccess: classes.containedSuccess,
                containedWarning: classes.containedWarn,
                containedError: classes.containedError,
                textPrimary: classes.textPrimary,
                textError: classes.textError,
                textSuccess: classes.textSuccess,
                textWarning: classes.textWarn,
                outlinedPrimary: classes.outlinedPrimary,
                outlinedError: classes.outlinedError,
                outlinedWarning: classes.outlinedWarning,
                outlinedSuccess: classes.outlinedSuccess,
            }}
            sx={{ ...sx }}
            startIcon={startIcon}
        >
            {children}
        </MuiButton>
    );
};
