import { CircularProgress, SxProps, Theme } from '@mui/material';
import { Grid } from '../Grid';

export function Loading({ rootSx }: { rootSx?: SxProps<Theme> }) {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={[{ height: '100%' }, ...(Array.isArray(rootSx) ? rootSx : [rootSx])]}
        >
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}
