import { useQuery } from '@tanstack/react-query';
import { backendServices } from '../services';

/**
 * Custom hook that fetches company data based on the company ID.
 *
 * @param companyId - The company ID.
 * @returns An object containing the company data, loading state, and error state.
 */
export function useCompany({ companyId, enabled = true }: { companyId: number | null; enabled?: boolean }) {
    const { isLoading, error, data, isFetching, status } = useQuery(
        ['Company', companyId],
        async () => {
            const api = new backendServices.Apis.CompanyApi();
            const res = await api.getCompany(companyId!);

            return res.data[0];
        },
        {
            enabled: enabled && !!companyId,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    return {
        isLoading: isLoading || isFetching,
        error,
        data,
        status,
    };
}
