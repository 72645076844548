import { Box, ScopedCssBaseline } from '@mui/material';
import { FC } from 'react';
import { LoginPage } from 'common/build/legacy/transcepta-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { isIE } from 'common/build/legacy/transcepta-common';
import {
    authUserByLoginPassword,
    autoLoginAction,
    resetPassword,
    resetPasswordRecoverStatus,
} from '../../../actions/userlogin';
import LoginSupplierArticle from './LoginSupplierArticle';
import LoginSupplierLogo from './LoginSupplierLogo';
import { isUserLoggedIn, UserNotification } from 'common';

const Login: FC = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state as any);
    const isLoggedIn = isUserLoggedIn();

    const autoLogin = async () => {
        await dispatch(autoLoginAction());
    };

    const signIn = async (email: string, password: string) => {
        await dispatch(authUserByLoginPassword(email, password));
    };

    const reset = async (email: string) => {
        await dispatch(resetPassword(email));
    };

    const resetRecoverStatus = () => {
        dispatch(resetPasswordRecoverStatus());
    };

    const root = (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '15vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                }}
            >
                {!isLoggedIn && <UserNotification loginPage={true} isBuyerPortal={false} />}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '85vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'translateY(-7.5vh)', // Center the login form. The box above is always present with a vh of 15.
                }}
            >
                <LoginPage
                    article={<LoginSupplierArticle />}
                    signIn={signIn}
                    reset={reset}
                    logo={<LoginSupplierLogo />}
                    resetRecoverStatus={resetRecoverStatus}
                    userLogin={selector.userlogin}
                    autoLogin={autoLogin}
                />
            </Box>
        </Box>
    );

    if (isIE()) {
        return root;
    }

    return <ScopedCssBaseline>{root}</ScopedCssBaseline>;
};

export default Login;
