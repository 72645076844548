/**
 * This file is meant to store enums that already exist in
 * the backend code, do not add Front-end only enums here.
 */

export enum BusinessDocType {
    Undefined,
    Invoice,
    PurchaseOrder,
    PurchaseOrderAcknowledgement,
    Unknown,
    ASN,
    InventoryAdvice,
    ReceivingAdvice,
    ConsumptionAdvice,
    ASNGeneric,
    FunctionalACK,
    NullDocument,
    SIMDocument,
}

export enum OnboardingStatus {
    Pending = 1,
    Completed = 2,
    ScanKey = 3,
}

export enum DDOfferAcceptanceMode {
    Normal = 0,
    AutoAccept = 1,
    AutoDecline = 2,
}

export enum CreditStatus {
    Active = 1,
    Hold = 2,
    Closed = 3,
    Bad = 4,
}

export enum BillingMethod {
    Transcepta = 1,
}

export enum BillingPlan {
    PerTransaction = 1,
}

export enum DocumentReleaseSchedule {
    ReleaseNow = 1,
    NextReleaseTime = 2,
    NextReleaseTimePlus24 = 3,
    NextReleaseTimePlus48 = 4,
}

export enum InvoiceConfirmationSchedule {
    Individual = 1,
    Batch = 2,
    Both = 3,
    None = 4,
}

export enum RemittanceAdviceSchedule {
    Individual = 0,
}

export enum CustomerPaymentType {
    ACH = 1,
    CreditCard = 2,
    All = 3,
    None = 4,
}

export enum PostFormat {
    NotApplicable = 0,
    BlackAndWhite = 1,
    Color = 2,
}

export enum GroupingMethod {
    Separate = 1,
    Combined = 2,
}

export enum GroupReleaseSchedule {
    NextReleaseTime = 1,
    DocumentBatch = 2,
}

export enum CustomerDeliverySetting {
    UseBuyer = 1,
    UseCustomer = 2,
    DoNotSend = 3,
}

export enum PostEnvelopeSize {
    Env10 = 1,
    Env6x9 = 2,
    Env9x12 = 3,
}

export enum RemittanceAdviceType {
    AdvanceRemittanceAdvice = 1,
    RemittanceAdvice = 2,
    Both = 3,
    None = 4,
}

export enum DeliveryMethod {
    Email = 2,
    Fax = 3,
    Post = 4,
    None = 5,
    Electronic = 6,
    OracleSupplierNetwork = 7,
    FTP = 8,
    WebService = 9,
    TransceptaManager = 10,
}
export enum RegistrationStatus {
    Pending = 1,
    Completed = 2,
    Registered = 3,
}

export enum SupplierCompanyOsnStatus {
    NotRequired = 1,
    Pending = 2,
    Registered = 3,
}

export enum DocumentHandling {
    Normal = 0,
    Symbeo = 1,
    SymbeoPrimary = 2,
    SymbeoFailure = 4,
}

export enum CompanyContactType {
    Support = 1,
    Billing = 2,
    RemittanceInstructions = 3,
}

// ! TODO: enable all options once they're supported by back-end
export enum PurchaseOrderFileFormat {
    // CSV = 1,
    // OAGXML = 2,
    PDF = 3,
    // EDI = 4,
}

export enum FileType {
    PDF = 1,
    TIFF = 2,
    XML = 3,
    UNKNOWN = 4,
    XML2 = 5,
    Attachment = 6,
}

export enum RuleStatus {
    Sample = 1,
    Active = 2,
    Closed = 3,
    WaitingForIndirect = 4,
    IndirectWillNotDevelop = 5,
}

export enum SignVATInvoices {
    Supplier = 0,
    Transcepta = 1,
    Both = 2,
}

export enum ContactType {
    Vendor = 1,
    Buyer = 2,
    ShipFrom = 3,
    ShipTo = 4,
    RemitTo = 5,
    SoldTo = 6,
    PurchaseFrom = 7,
    Bank = 8,
}

export enum CompanyOnboardingStatus {
    Undefined,
    InTrial,
    Live,
}

export enum UserNotificationTypes {
    BuyerPortal = 1,
    SupplierPortal,
    BothPortals,
    Email,
    BuyerPortalLogin,
    SupplierPortalLogin,
    BothLogin,
}

export enum PurgeStatus {
    Completed = 1,
    Failed = 2,
}

export enum CompanyCustomDatasetColumnType {
    Email = 1,
    CustomEnum = 2,
    Integer = 3,
    Float = 4,
    String = 5,
    Date = 6,
    Currency = 7,
    Boolean = 8,
}

export enum MessageType {
    InvoiceBatch = 1,
    OLDUpdateInvoiceStatus = 2,
    POUploadBatch = 3,
    Unknown = 4,
    PODeliveryBatch = 5,
    POAcknowledgementBatch = 6,
    UpdatePOStatus = 7,
    ItemMasterBatch = 8,
    Ignore = 9,
    ASNBatch = 10,
    FunctionalAckOutbound = 11,
    InventoryAdvice = 12,
    ReceivingAdviceNormalShipments = 13,
    ReceivingAdviceReturnedGoods = 14,
    ConsumptionAdvice = 15,
    FunctionalAckInbound = 16,
    ASNGeneric = 17,
    FunctionalACK = 18,
    ZIP = 19,
    NullDocument = 20,
    Attachment = 21,
    CompanyUsersLoad = 22,
    ScanKeySourceFile = 23,
    ScanKeyInvoiceXml = 24,
    ScanKeyInvoiceImage = 25,
    VendorMaster = 26,
    CompanyCustomDataset = 27,
    CompanyGLAccountBatch = 28,
    DocumentAttachments = 29,
    InvoiceStatusUpdate = 30,
    ScanKeySourceFileEmailForward = 31,
    ScanKeyInvoiceXmlDocumentCreation = 32,
    ScanKeySourceFileEmailForward_AI_OCR = 33,
    ScanKeySourceFileEmailForward_AI = 34,
    ScanKeySourceFileEmailForward_OCR = 35,
    PaymentInstruction = 36,
    PaymentStatusExternalProvider = 37,
    Undefined = -1,
}

export enum BatchType {
    Complete = 1,
    Incremental = 2,
    StatusUpdate = 3,
    CompleteSubset = 4,
    Partial = 5,
}

export enum ArchiveStatus {
    Archived = 1,
    Expired,
    Purged,
    NotArchived,
}

export enum MessageSource {
    VirtualPrinter = 1,
    WebEntry = 2,
    Email = 3,
    FTP = 4,
    VirtualPrinterDownload = 5,
    CustomerServicePortalUpload = 6,
    DeliveryWare = 7,
    DPS = 8,
    Post = 9,
    Fax = 10,
    ScanServicePOFeed = 11,
    PaymentStatusExternalProvider = 12,
    WebUpload = 13,
    FileUpload = 14,
    Undefined = 0, // Assuming 'Undefined' should be represented by 0 or another appropriate value
}

export enum MessageStatus {
    Received = 1,
    Transformed = 2,
    Published = 3, // success for data upload
    Research = 4, // failure for data upload
    RecognitionComplete = 5,
    Canceled = 6,
    Transmitting = 7,
    HoldForUnknownUser = 8,
    InvoiceProfileVerified = 9,
    DeliverwareRecognition = 10,
    DeliverywareRecognitionComplete = 11,
    HoldingForBatchProcessing = 12,
    HoldProcessing = 13,
    Released = 14,
    WaitingForScanService = 15,
    Undefined = 0, // Assuming 'Undefined' should be represented by 0 or another appropriate value
}

export enum UserNotificationPortalAcknowledgement {
    None = 0,
    YesWithNoBlocking = 1,
    YesWithBlocking = 2,
}

// -- for dashboard metadata --
export enum DashboardMenu {
    Overview = 1,
    StraightThroughProcessing = 2,
    Invoices = 3,
    Suppliers = 4,
    Spend = 5,
}

// TODO: confirm with backend
export enum DashboardSubMenu {
    None = 'None',
    InvoicesDelivered = 'Invoices Delivered',
    InvoicesRejected = 'Invoices Rejected',
}

export enum DashboardExportType {
    TransactionsPage = 1,
    DrilldownPopup = 2,
    DrilldownCSV = 3,
    NoDrilldown = 4,
}

export enum GraphType {
    Bar = 1,
    Line = 2,
    Pie = 3,
    Donut = 4,
    Table = 5,
    Cards = 6, // TODO: confirm with backend
}

export enum AxisType {
    Number = 1,
    String = 2,
    Date = 3,
    Currency = 4,
    Percentage = 5,
    DateGroup = 6,
}
// --
