import { useQuery } from '@tanstack/react-query';
import { useCurrentCompanyId } from '../../PortalUserService';
import { UserService } from '../UserService';

/**
 * @deprecated Do not use in new code
 *
 * This is currently used in UserCRUD.tsx and UserGroupCRUD.tsx
 * TODO: update the two components to use hooks/useMultiErpUsers or hooks/useCompanyUsers instead
 */
export const useUsers = (childCompanyId?: string, enabled: boolean = true) => {
    const companyId = useCurrentCompanyId();
    const { data, isFetching, error } = useQuery(
        ['Users', companyId, childCompanyId],
        async () => {
            const userService = new UserService();
            return userService.getUsers(companyId, childCompanyId);
        },
        {
            enabled,
        }
    );

    return {
        users: data ?? [],
        isFetching,
        error,
    };
};
