import { ISearchCriteria } from './ISearchCriteria';
import { ISearchResult } from './ISearchResult';

export const enum SearchCriteriaType {
    DateRange = 'DateRange',
    NumericRange = 'NumericRange',
    Select = 'Select',
    Input = 'Input',
    Checkbox = 'Checkbox',
}

export interface IValidationResult {
    hasErrors: boolean;
    message?: string;
    data?: ISearchCriteria[] | ISearchResult;
}
