import { AxisType, DashboardExportType, DashboardMenu, DashboardSubMenu, GraphType } from '../../../types';
import { z } from 'zod';

export const AxisDefinitionSchema = z.object({
    name: z.string().trim().min(1, 'Name is required'), // internal name
    label: z.string().optional().nullable(), // display name (for values of bar/line graphs, for keys of pie/donut graphs)
    type: z.nativeEnum(AxisType),
    startDate: z.string().optional(), // only used for DateGroup axis type
    endDate: z.string().optional(), // only used for DateGroup axis type
});

export const GraphConfigurationDetailSchema = z
    .object({
        graphType: z.nativeEnum(GraphType),
        keyAxisDefinition: AxisDefinitionSchema.optional(), // x-axis for bar, line(s), pie and donut use it as slice name, tables and cards don't use it
        valueAxisDefinition: z
            .array(AxisDefinitionSchema)
            .min(1, 'At least one y-axis/slice value/column/card definition is required'), // y-axis for bar, line(s), pie and donut use it as slice value, tables and cards use it as column name
        valueAxisMaxValue: z.number().optional(), // not used for pie/donut
        fillLineArea: z.boolean().optional(), // only used for line graphs
        showPercentageInSlice: z.boolean().optional(), // only used for pie/donut graphs
        hideLegend: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
        if (data.graphType === GraphType.Bar || data.graphType === GraphType.Line) {
            if (!data.keyAxisDefinition?.name) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['keyAxisDefinition'],
                    message: 'Horizontal axis name is required',
                });
            }
        }
        if (data.graphType === GraphType.Pie || data.graphType === GraphType.Donut) {
            if (!data.keyAxisDefinition?.name) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['keyAxisDefinition'],
                    message: 'Slice key name is required',
                });
            }
        }
        if (data.graphType === GraphType.Line || data.graphType === GraphType.Bar) {
            if (
                data.keyAxisDefinition?.type === AxisType.DateGroup &&
                (!data.keyAxisDefinition.startDate || !data.keyAxisDefinition.endDate)
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['keyAxisDefinition'],
                    message: 'Start and end date are required for date group axis',
                });
            }
        }
    });

export const GraphConfigurationSchema = z.object({
    root: GraphConfigurationDetailSchema,
});

export const DashboardReportMetadataSchema = z
    .object({
        Id: z.number().optional(),
        Name: z.string().trim().min(1, 'Name is required'),
        Description: z.string().trim().min(1, 'Description is required'),
        Menu: z.nativeEnum(DashboardMenu),
        SubMenu: z.nativeEnum(DashboardSubMenu).nullable(),
        ToolTip: z.string().optional().nullable(),
        GraphSP: z.string().trim().min(1, 'Graph SP is required'),
        ExportSP: z.string().optional().nullable(),
        ExportBusinessDocTypeSearchId: z.string().optional().nullable(),
        /**
         * It controls the drill-down type, doesn't affect the "export" button
         */
        ExportType: z.nativeEnum(DashboardExportType),
        Order: z.number().min(1, 'Order must be at least 1'),
        GraphType: z.nativeEnum(GraphType),
        GraphConfiguration: GraphConfigurationSchema,
        MaxExportDays: z
            .number()
            .optional()
            .refine((val) => val === undefined || val >= 1, {
                message: 'Max export days must be at least 1',
            })
            .nullable(),
        BundleName: z.string().optional(),
        CompanyId: z.number().optional().nullable(),
    })
    .superRefine((data, ctx) => {
        if (data.ExportType === DashboardExportType.TransactionsPage && !data.ExportBusinessDocTypeSearchId) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Export business doc type search ID is required for search drilldown',
            });
        }
        if (data.ExportType === DashboardExportType.DrilldownCSV && !data.ExportSP) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Export SP is required for drilldown CSV export',
            });
        }
    });

export type AxisDefinition = z.infer<typeof AxisDefinitionSchema>;
export type GraphConfigurationDetailViewModel = z.infer<typeof GraphConfigurationDetailSchema>;
export type GraphConfigurationViewModel = z.infer<typeof GraphConfigurationSchema>;

/**
 * Backend stores the graph configuration as XML, so API returns all config fields as strings.
 */
export interface GraphConfigurationRawViewModel {
    root: {
        graphType: string;
        keyAxisDefinition?: {
            name: string;
            label?: string;
            type: string;
            startDate?: string;
            endDate?: string;
        };
        valueAxisDefinition: Array<{
            name: string;
            label?: string;
            type: string;
        }>;
        valueAxisMaxValue?: string;
        fillLineArea?: string;
        showPercentageInSlice?: string;
        hideLegend?: string;
    };
}

export interface DashboardReportMetadataViewModel {
    Id?: number;
    Name: string;
    Description: string;
    Menu: DashboardMenu;
    Menu_US?: string;
    SubMenu: DashboardSubMenu | null;
    ToolTip: string | null;
    GraphSP: string;
    ExportSP: string | null;
    ExportBusinessDocTypeSearchId: string | null;
    ExportType: DashboardExportType;
    ExportType_US?: string;
    Order: number;
    GraphType: GraphType;
    GraphType_US?: string;
    GraphConfiguration: GraphConfigurationViewModel;
    MaxExportDays: number | null;
    BundleName?: string;
    CompanyId?: number | null;
}

export type GraphConfigurationDetailForm = z.infer<typeof GraphConfigurationDetailSchema>;
export type GraphConfigurationForm = z.infer<typeof GraphConfigurationSchema>;
export type DashboardReportMetadataForm = z.infer<typeof DashboardReportMetadataSchema>;
